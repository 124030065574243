import { apiService } from "../core";
import { consoleError } from "../utils/consoleError";
import dayjs from "dayjs";

const filterData = (data, query) => {
  if (query && query?.trim() !== "")
    return data.reduce((prev, curr) => {
      const reports = curr.reports.filter(r =>
        r.reportName.toLowerCase().includes(query.toLowerCase())
      );
      if (reports.length === 0) return prev;
      return [...prev, { ...curr, reports }];
    }, []);
  return data;
};

const getPayrollReports = async (
  startDate,
  endDate,
  search,
  prNumber,
  year,
  starredOnly,
  companyNumber
) => {
  if (companyNumber == null) return;
  let uri = `api/reports/${companyNumber}/PrimaryReports/PayrollReports`;
  const query = [];
  if (startDate) {
    query.push(`FromDate=${dayjs(startDate).format("l")}`);
  }
  if (endDate) {
    query.push(`ToDate=${dayjs(endDate).format("l")}`);
  }
  if (prNumber) {
    uri = `${uri}`;
    query.push(`Prnum=${prNumber}`);
  }
  if (starredOnly) {
    uri = `${uri}`;
    query.push(`StarredOnly=${starredOnly}`);
  }
  if (query.length > 0) uri = `${uri}?${query.join("&")}`;
  const { data } = await apiService.get(uri);
  return filterData(data, search);
};

const getDistributedPayStubsReport = async (
  startDate,
  endDate,
  search,
  prNumber,
  year,
  starredOnly,
  companyNumber
) => {
  if (companyNumber == null) return;
  let uri = `api/reports/${companyNumber}/PrimaryReports/DistributedPayStubsReport`;
  const query = [];
  if (startDate) {
    query.push(`FromDate=${dayjs(startDate).format("l")}`);
  }
  if (endDate) {
    query.push(`ToDate=${dayjs(endDate).format("l")}`);
  }
  if (prNumber) {
    uri = `${uri}`;
    query.push(`Prnum=${prNumber}`);
  }
  if (starredOnly) {
    uri = `${uri}`;
    query.push(`StarredOnly=${starredOnly}`);
  }
  if (query.length > 0) uri = `${uri}?${query.join("&")}`;
  const { data } = await apiService.get(uri);
  return filterData(data, search);
};

const getPeriodEndReports = async (
  startDate,
  endDate,
  search,
  prNumber,
  year,
  starredOnly,
  companyNumber
) => {
  if (companyNumber == null) return;
  let uri = `api/reports/${companyNumber}/PrimaryReports/PeriodEndReports`;
  const query = [];
  if (year) {
    uri = `${uri}`;
    query.push(`Year=${year}`);
  }
  if (starredOnly) {
    uri = `${uri}`;
    query.push(`StarredOnly=${starredOnly}`);
  }
  if (query.length > 0) uri = `${uri}?${query.join("&")}`;
  const { data } = await apiService.get(uri);
  return filterData(data, search);
};

const getDistributedW2s = async (
  startDate,
  endDate,
  search,
  prNumber,
  year,
  starredOnly,
  companyNumber
) => {
  if (companyNumber == null) return;
  let uri = `api/reports/${companyNumber}/PrimaryReports/DistributedW2s`;
  const query = [];
  if (year) {
    uri = `${uri}`;
    query.push(`Year=${year}`);
  }
  if (starredOnly) {
    uri = `${uri}`;
    query.push(`StarredOnly=${starredOnly}`);
  }
  if (query.length > 0) uri = `${uri}?${query.join("&")}`;
  const { data } = await apiService.get(uri);
  return filterData(data, search);
};

const downloadReport = async (reportId, fileName = "report", companyNumber) => {
  if (companyNumber == null || reportId == null) return;
  const fileNameWithExtension = `${fileName}.pdf`;
  let uri = `api/reports/${companyNumber}/PrimaryReports/DownloadReport/${reportId}`;
  await apiService.downloadFile(uri, fileNameWithExtension, consoleError);
};

const downloadGroupedReports = async (
  reportType = null,
  year = null,
  quarter = null,
  prnum = null,
  fileName = "groupedReports",
  starredOnly,
  companyNumber
) => {
  if (companyNumber == null) return;
  const fileNameWithExtension = `${fileName}.pdf`;
  const body = {
    reportType,
    year,
    quarter,
    prnum,
    starredOnly
  };
  let uri = `api/reports/${companyNumber}/PrimaryReports/DownloadGroupedReports`;
  await apiService.downloadFileViaPost(uri, body, fileNameWithExtension, consoleError);
};

const getReportURL = async (reportId, fileName = "report", companyNumber) => {
  if (companyNumber == null || reportId == null) return;
  const fileNameWithExtension = `${fileName}.pdf`;
  const url = await apiService.getFile(
    `api/reports/${companyNumber}/ReportHistory/DownloadReport/${reportId}`,
    fileNameWithExtension,
    consoleError
  );
  return url;
};

const getPayrollNumbers = async companyNumber => {
  if (companyNumber == null) return;
  let uri = `api/reports/${companyNumber}/PrimaryReports/PayrolNumbers`;
  const { data } = await apiService.get(uri);
  return data;
};

const getAvailableYears = async (type, companyNumber) => {
  if (companyNumber == null || type == null) return;
  let uri = `api/reports/${companyNumber}/PrimaryReports/AvailYears?type=${type}`;
  const { data } = await apiService.get(uri);
  return data;
};

export const addToFavorite = async ({ reportId, companyNumber }) => {
  if (companyNumber == null || reportId == null) return;
  let uri = `api/reports/${companyNumber}/PrimaryReports/${reportId}/Stars`;
  const { data } = await apiService.post(uri, {});
  return data;
};

export const removeFromFavorite = async ({ reportId, companyNumber }) => {
  if (companyNumber == null || reportId == null) return;
  let uri = `api/reports/${companyNumber}/PrimaryReports/${reportId}/Stars`;
  const { data } = await apiService.delete(uri, {});
  return data;
};

export const reportHistoryApi = {
  getPayrollReports,
  getDistributedPayStubsReport,
  getPeriodEndReports,
  getDistributedW2s,
  downloadReport,
  downloadGroupedReports,
  getReportURL,
  getPayrollNumbers,
  getAvailableYears,
  addToFavorite,
  removeFromFavorite
};
