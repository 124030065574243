import * as React from 'react';
import { useSnackbar } from "notistack";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Payees from './Payees';
import Deductions from './Deductions';
import Checks from './Checks';
import { apiService, axiosError } from '../../../core';
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";
import CheckMasterBatch from './CheckMasterBatch';
import { useHistory, useLocation } from "react-router-dom";
import CheckPayrollIssues from './CheckPayrollIssues';
import MpwClientErrors from './MpwClientErrors';
import CheckPayrollIssuesLog from './CheckPayrollIssuesLog';
import PayrollStatusChanges from './PayrollStatusChanges';
import PayrollsInProcess from './PayrollsInProcess';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index} style={{flex: 1, overflowY: 'auto'}}>
      {value === index && children}
    </div>
  );
}

export default function PayrollDetails(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const payrollNumber = props.data ? props.data.data.prnum : queryParams.get('prnum');
  const [value, setValue] = React.useState(0);
  const [payees, setPayees] = React.useState([]);
  const [deductions, setDeductions] = React.useState([]);
  const [checks, setChecks] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    getPayees();
    getDeductions();
    getChecks();
  }, [payrollNumber]);

  const getPayees = async () => {
    try {
      const response = await apiService.get(`api/Internal/Payrolls/${payrollNumber}/Earnings`);
      setPayees(response?.data?.data);
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    }
  };

  const getDeductions = async () => {
    try {
      const response = await apiService.get(`api/Internal/Payrolls/${payrollNumber}/Deductions`);
      setDeductions(response?.data?.data);
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    }
  };

  const getChecks = async () => {
    try {
      const response = await apiService.get(`api/Internal/Payrolls/${payrollNumber}/Checks`);
      setChecks(response?.data?.data);
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const refreshPayees = () => {
    getPayees();
    getChecks();
  }

  const refreshDeductions = () => {
    getDeductions();
    getChecks();
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }} id="payroll_detail_page" display='flex' flexDirection='column'>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} id="payroll_detail_tabs">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Checks" />
          <Tab label="Earnings" />
          <Tab label="Deductions" />
          <Tab label="CheckMasterBatch" />
          <Tab label="Check Payroll Issues" />
          <Tab label="Mpw Client Errors" />
          <Tab label="Check Payroll Issues Log" />
          <Tab label="Payroll Status Changes" />
          <Tab label="Payrolls In Process" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Checks data={checks}></Checks>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Payees payees={payees} payrollNumber={payrollNumber} payeesUpdated={refreshPayees}></Payees>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Deductions deductions={deductions} payrollNumber={payrollNumber} deductionsUpdated={refreshDeductions}></Deductions>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CheckMasterBatch payrollNumber={payrollNumber} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CheckPayrollIssues payrollNumber={payrollNumber} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <MpwClientErrors payrollNumber={payrollNumber} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <CheckPayrollIssuesLog payrollNumber={payrollNumber} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <PayrollStatusChanges payrollNumber={payrollNumber} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <PayrollsInProcess payrollNumber={payrollNumber} />
      </TabPanel>
    </Box>
  );
}