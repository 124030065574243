import React from "react";
import { authService, logErrorsService } from "../core";

export const signalRAutoStartAndCleanUp = connection => {
  const start = async () => {
    if (authService.getTokenClaim("BrandsPay_UserType") === "Brands" && !authService.isTokenExpired()) {
      try {
        console.log("signalR: ", "Connecting");
        await connection?.start();
        console.log("signalR: ", "Connected");
        logErrorsService.info('signalR Connected.');
        return;
      } catch (err) {
        logErrorsService.error(`signalR: Error. isUserAuthenticated: ${authService.isUserAuthenticated()}`, err);
        if (!authService.isUserAuthenticated()) {
          shouldAutoStart = false;
        }
      }
    }
    setTimeout(() => start(), 5000);
  };

  let shouldAutoStart = true;
  connection?.onclose(function (error) {
    logErrorsService.info(`signalR: onclose. shouldAutoStart: ${shouldAutoStart}`, error);
    if (shouldAutoStart) {
      start();
    }
  });

  
  start();

  return function cleanUp() {
    console.log("signalR: ", "cleanUp");
    shouldAutoStart = false;
    try {
      connection?.stop();
    } catch (err) {
      logErrorsService.error("Error in cleaUp", err);
    }
  };
};

export const SignalRContext = React.createContext(null);
