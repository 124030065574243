import * as React from 'react';
import { useSnackbar } from "notistack";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { apiService, axiosError } from '../../../core';
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";

export default function CreatePowerGrid(props) {
  const [inputData, setInputData] = React.useState({
    schemaId: ''
  });
  const openProp = props.open;
  const [open, setOpen] = React.useState(openProp);
  const [schemas, setSchemas] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    const getSchemas = async () => {
      try {
        const response = await apiService.get(`api/Internal/PowerGrids/Schemas`);
        setSchemas(response?.data);
      } catch(err) {
        enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
      }
    };

    if (openProp) {
      getSchemas();
    }
    setOpen(openProp);
  }, [openProp]);

  const handleClose = () => {
    setOpen(false);
    props.openChange(false);
  };

  const handleSchemaChange = (event) => {
    const { target: { value }} = event;
    setInputData({
      schemaId: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.createPowerGrid(inputData);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create power grid</DialogTitle>
          <DialogContent style={{paddingTop: '10px'}}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <InputLabel>Schema</InputLabel>
                  <Select
                    value={inputData.schemaId}
                    onChange={handleSchemaChange}
                    input={<OutlinedInput label="Schema" />}
                  >
                    {schemas.map((c) => (
                      <MenuItem
                        key={c.id}
                        value={c.id}
                      >
                        { c.name }
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose} type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}