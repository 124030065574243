import { PAGES } from "../routes/pages";
import authService from "../core/authService";
import { logErrorsService } from "../core";
import { localStorageSyncApi } from "../core/localStorageSyncApi";
import { sessionStorageSyncApi } from "../core/sessionStorageSyncApi";

let redirectToLoginTimeoutId;

export const redirectToLoginTimerClear = () => {
  if (redirectToLoginTimeoutId) {
    window.clearTimeout(redirectToLoginTimeoutId);
    redirectToLoginTimeoutId = undefined;
  }
};

export const redirectToLoginTimerStart = (history, locationPath) => {
  try {
    redirectToLoginTimerClear();
    const millisecondsTillExpiration = authService.getMillisecondsTillExpiration();
    redirectToLoginTimeoutId = window.setTimeout(() => {
      logErrorsService.info("redirectToLoginTimeout");
      localStorageSyncApi.removeJwtToken();
      sessionStorageSyncApi.removeJwtToken();
      history.push(PAGES.brandsLogin.path, { prevLocation: locationPath });
    }, millisecondsTillExpiration);
  } catch (ex) {
    logErrorsService.error(`Error in redirectToLoginTimerStart - ${ex.message}`);
    return undefined;
  }
};
