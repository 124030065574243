import React, { useState, useEffect, useRef } from "react";
import {
  DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
  Paging, SearchPanel, Sorting, Column, Toolbar, Item, Lookup, Editing, MasterDetail
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { Box } from "@mui/system";
import { urlHelpers } from "../../../core";

export default function PayrollsInProcess({payrollNumber}) {
  const [remoteDataSource, setRemoteDataSource] = useState();
  const [focusedRowPrNum, setFocusedRowPrNum] = useState();
  const dataGrid = useRef();

  useEffect(() => {
    const getPayrolls = async () => {
      setRemoteDataSource(
        createStore({
          key: "id",
          loadUrl: urlHelpers.getAbsoluteURL(`api/Internal/Payrolls/${payrollNumber}/PayrollsInProcess`),
          updateUrl: urlHelpers.getAbsoluteURL(`api/Internal/Payrolls/${payrollNumber}/PayrollsInProcess`),
          onBeforeSend: (e, s) => {
            s.headers = {
              Authorization: `Bearer ${localStorage.getItem("BrandsWebJWT")}`
            };
          }
        })
      );
    };

    getPayrolls();
  }, [payrollNumber]);

  const onFocusedRowChanging = e => {
    setFocusedRowPrNum(e.row && e.row.data?.prNum);
  };

  const refreshPayrolls = () => {
    dataGrid.current.instance.refresh()
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <DataGrid
        ref={dataGrid}
        dataSource={remoteDataSource}
        height="100%"
        width="100%"
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        showBorders={true}
        columnResizingMode={"widget"}
        showColumnLines={false}
        rowAlternationEnabled
        focusedRowEnabled
        onFocusedRowChanged={onFocusedRowChanging}
        autoNavigateToFocusedRow
      >
        <Toolbar>
          <Item location="before">
            <Button icon="refresh" onClick={refreshPayrolls} />
          </Item>
          <Item name="columnChooserButton" locateInMenu="auto" location="after" />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <Editing mode="cell" allowDeleting={false} allowUpdating={true} />
        <SearchPanel visible width={250} />
        <HeaderFilter visible allowSearch />
        <ColumnChooser enabled />
        <Sorting mode="multiple" />
        <ColumnFixing />
        <Paging defaultPageSize={40} />
        <Pager showPageSizeSelector />
        <FilterPanel visible />
        <FilterBuilderPopup />
      </DataGrid>
    </Box>
  );
}
