import React, { useState, useEffect, useRef } from "react";
import {
  DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
  Paging, SearchPanel, Sorting, Column, Toolbar, Item, Lookup, Editing, MasterDetail, Button
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";
import { apiService, axiosError, urlHelpers } from "../../../core";
import PayrollDetails from "./PayrollDetails";
import AddPayroll from "./AddPayroll";
import { useHistory } from "react-router-dom";

export default function Payrolls(props) {
  const [remoteDataSource, setRemoteDataSource] = useState();
  const [focusedRowPrNum, setFocusedRowPrNum] = useState();
  const [isPayrollFormVisible, setIsPayrollFormVisible] = useState(false);
  const dataGrid = useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const getPayrolls = async () => {
      setRemoteDataSource(
        createStore({
          key: "rowGuid",
          loadUrl: urlHelpers.getAbsoluteURL("api/Internal/Payrolls"),
          updateUrl: urlHelpers.getAbsoluteURL("api/Internal/Payrolls/Update"),
          onBeforeSend: (e, s) => {
            s.headers = {
              Authorization: `Bearer ${localStorage.getItem("BrandsWebJWT")}`
            };
          }
        })
      );
    };

    getPayrolls();
  }, []);

  const onFocusedRowChanging = e => {
    setFocusedRowPrNum(e.row && e.row.data?.prnum);
  };

  const onItemClicked = async e => {
    dataGrid.current.instance.beginCustomLoading();
    try {
      if (e.itemData.id === 1) {
        await apiService.delete(`api/Internal/Payrolls/${focusedRowPrNum}`);
      } else if (e.itemData.id === 2) {
        await apiService.post(`api/Internal/Payrolls/${focusedRowPrNum}/Totals`, {
          prnum: focusedRowPrNum
        });
      } else if (e.itemData.id === 3) {
        await apiService.post(`api/Internal/Payrolls/${focusedRowPrNum}`);
      }
      dataGrid.current.instance.refresh()
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    } finally {
      dataGrid.current.instance.endCustomLoading();
    }
  }

  const openChange = async e => {
    setIsPayrollFormVisible(e);
  }

  const showAddPayrollForm = e => {
    setIsPayrollFormVisible(true);
  }

  const addPayroll = async (data) => {
    try {
      dataGrid.current.instance.beginCustomLoading("adding payroll");
      await apiService.post(`api/Internal/Payrolls`, data);
      refreshPayrolls();
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    } finally {
      dataGrid.current.instance.endCustomLoading();
    }
  }

  const refreshPayrolls = () => {
    dataGrid.current.instance.refresh()
  }

  const actions = [
    { id: 1, text: "Delete Payroll", icon: "trash" },
    { id: 2, text: "Calculate Totals", icon: "percent" },
    { id: 3, text: "Submit Payroll", icon: "save" }
  ];

  const payrollDetails = (e) => {
    console.log(e);
    history.push(`/internal/super_admin/payroll/details?prnum=${e.row.data.prnum}`);
    e.event.preventDefault();
  }

  const payrollStatuses = ["Entering Checks", "Submitted", "DD Ready", "Done"];

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <AddPayroll open={isPayrollFormVisible} openChange={openChange} addPayroll={addPayroll}></AddPayroll>
      <DataGrid
        ref={dataGrid}
        dataSource={remoteDataSource}
        height="100%"
        width="100%"
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        showBorders={true}
        columnResizingMode={"widget"}
        showColumnLines={false}
        rowAlternationEnabled
        focusedRowEnabled
        onFocusedRowChanged={onFocusedRowChanging}
        autoNavigateToFocusedRow
      >
        <Toolbar>
          <Item location="before">
            <Button icon="refresh" onClick={refreshPayrolls} />
          </Item>
          <Item location="before">
            <DropDownButton
              text="Payroll Actions"
              disabled={!focusedRowPrNum}
              items={actions}
              onItemClick={onItemClicked}
              keyExpr="id"
              displayExpr="text"
            />
          </Item>
          <Item location="after">
            <Button icon="add" onClick={showAddPayrollForm} />
          </Item>
          <Item name="columnChooserButton" locateInMenu="auto" location="after" />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <Editing mode="cell" allowDeleting={false} allowUpdating={true} />
        <SearchPanel visible width={250} />
        <HeaderFilter visible allowSearch />
        <ColumnChooser enabled />
        <Sorting mode="multiple" />
        <ColumnFixing />
        <Paging defaultPageSize={40} />
        <Pager showPageSizeSelector />
        <FilterPanel visible />
        <FilterBuilderPopup />

        <Column type="buttons" width={35}>
          <Button hint="Details" icon="showpanel" onClick={payrollDetails} />
        </Column>
        <Column dataField="conum" allowEditing={false}/>
        <Column dataField="prnum" caption="Pr#" defaultSortIndex={0} defaultSortOrder="desc" allowEditing={false}/>
        <Column dataField="gross" dataType="number" format="currency" />
        <Column dataField="net" dataType="number" format="currency" />
        <Column dataField="checK_DATE" dataType="datetime" />
        <Column dataField="pyarollStatus">
          <Lookup dataSource={payrollStatuses} />
        </Column>
        <Column dataField="pR_DESCR" />
        <Column dataField="rowGuid" visible={false} />
        <Column dataField="firstPeriodStartDate" dataType="datetime" />
        <Column dataField="firstPeriodEndDate" dataType="datetime" />
        <Column dataField="secondPeriodStartDate" dataType="datetime" />
        <Column dataField="secondPeriodEndDate" dataType="datetime" />
        <Column dataField="thirdPeriodStartDate" dataType="datetime" />
        <Column dataField="thirdPeriodEndDate" dataType="datetime" />
        <MasterDetail
          enabled={true}
          component={PayrollDetails}
        />
      </DataGrid>
    </Box>
  );
}
