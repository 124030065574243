import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function CheckedIcon(props) {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g id="Check">
        <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#4426D9" fill="#4426D9" />
        <path
          id="Vector"
          d="M4.5 9.00037L7.68198 12.1823L14.0453 5.81836"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
}

export default CheckedIcon;
