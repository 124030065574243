import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "./rootReducer";
// import rootSaga from "./sagas";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export function configureStore(initState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  let store = null;

  if (composeEnhancers) {
    store = createStore(reducers, initState, composeEnhancers(applyMiddleware(sagaMiddleware)));
  } else {
    store = createStore(reducers, initState, applyMiddleware(sagaMiddleware));
  }

  // sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();
