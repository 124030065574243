export const CALENDAR_PERIODS = {
  THIS_YEAR: {
    id: "THIS_YEAR",
    name: "This year"
  },
  LAST_YEAR: {
    id: "LAST_YEAR",
    name: "Last year"
  },
  THIS_QUARTER: {
    id: "THIS_QUARTER",
    name: "This quarter"
  },
  LAST_QUARTER: {
    id: "LAST_QUARTER",
    name: "Last quarter"
  },
  CUSTOM: {
    id: "CUSTOM",
    name: "Custom"
  }
};

export const CALENDAR_PERIODS_ARR = Object.keys(CALENDAR_PERIODS).map(e => CALENDAR_PERIODS[e]);

export const CALENDAR_PERIODS_DICT = Object.keys(CALENDAR_PERIODS).map(e => ({
  key: e,
  value: CALENDAR_PERIODS[e].name
}));

export const EMPLOYEE_ACTIVE_STATUS = {
  ACTIVE: {
    key: "0",
    value: "Active"
  },
  HIRED_WITHIN_YEAR: {
    key: "1",
    value: "Hired within year"
  },
  TERMINATED_WITHIN_YEAR: {
    key: "2",
    value: "Terminated within year"
  },
  TERMINATED_ALL: {
    key: "3",
    value: "Terminated all"
  },
  ALL: {
    key: "4",
    value: "All"
  }
};

export const EMPLOYEE_ACTIVE_STATUS_DICT = Object.keys(EMPLOYEE_ACTIVE_STATUS).map(
  e => EMPLOYEE_ACTIVE_STATUS[e]
);
