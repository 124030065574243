// ACTION TYPES
export const TYPES = {
  OPEN_FEEDBACK_MODAL: "OPEN_FEEDBACK_MODAL",
  CLOSE_FEEDBACK_MODAL: "CLOSE_FEEDBACK_MODAL",
  FEEDBACK_IS_SENT: "FEEDBACK_IS_SENT"
};

// ACTION CREATORS
export const openFeedbackModalAC = () => ({ type: TYPES.OPEN_FEEDBACK_MODAL });
export const closeFeedbackModalAC = () => ({ type: TYPES.CLOSE_FEEDBACK_MODAL });
export const feedbackIsSentAC = () => ({ type: TYPES.FEEDBACK_IS_SENT });

// REDUCER
const initialState = {
  isModalOpened: false,
  isFeedbackSent: false
};

export const widgetFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_FEEDBACK_MODAL:
      return {
        ...state,
        isModalOpened: true,
        isFeedbackSent: false
      };
    case TYPES.CLOSE_FEEDBACK_MODAL:
      return {
        ...state,
        isModalOpened: false
      };
    case TYPES.FEEDBACK_IS_SENT:
      return {
        ...state,
        isFeedbackSent: true
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsModalOpened = state => state.widgetFeedback.isModalOpened;
export const selectIsFeedbackSent = state => state.widgetFeedback.isFeedbackSent;
