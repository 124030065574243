import React from 'react';
import { DataGrid, Column, Editing } from 'devextreme-react/data-grid';
import { apiService, axiosError } from '../../../core';

export default function Payees(props) {
  const payrollNumber = props.payrollNumber;
  const dataGrid = React.useRef();

  const insertRow = e => {
    dataGrid.current.instance.beginCustomLoading("adding payee.");
    const isCanceled = new Promise(async (resolve, reject) => {
      try {
        await apiService.post(`api/Internal/Payrolls/${payrollNumber}/Earnings`, e.data);
        props.payeesUpdated();
        resolve(false);
      } catch (err) {
        reject(axiosError.errorDescription(err));
      } finally {
        dataGrid.current.instance.endCustomLoading();
      }
    });
    e.cancel = isCanceled;
  }

  const removeRow = e => {
    dataGrid.current.instance.beginCustomLoading("adding payee.");
    const isCanceled = new Promise(async (resolve, reject) => {
      try {
        await apiService.delete(`api/Internal/Payrolls/${payrollNumber}/Earnings/${e.data.rowGuid}`);
        props.payeesUpdated();
        resolve(false);
      } catch (err) {
        reject(axiosError.errorDescription(err));
      } finally {
        dataGrid.current.instance.endCustomLoading();
      }
    });
    e.cancel = isCanceled;
  }

  return (
    <React.Fragment>
      <DataGrid
        height='100%'
        ref={dataGrid}
        dataSource={props.payees}
        showBorders={true}
        onRowInserting={insertRow}
        onRowRemoving={removeRow}>
        <Editing
          mode="row"
          allowDeleting={true}
          allowAdding={true} />
        <Column dataField="empNum" caption="Employee #" width={140} />
        <Column dataField="checkCounter" caption="Check counter" width={140} />
        <Column dataField="clCode" caption="Code" width={120} />
        <Column dataField="clDept" caption="Department #" width={140} />
        <Column dataField="divNum" caption="Division #" width={140} />
        <Column dataField="clOtHours" caption="OT Hours" width={120} />
        <Column dataField="clOtPay" caption="OT Pay" width={120} />
        <Column dataField="clRate" caption="Rate" width={120} />
        <Column dataField="clRegHours" caption="Reg Hours" width={120} />
        <Column dataField="clRegPay" caption="Reg Pay" width={120} />
      </DataGrid>
    </React.Fragment>
  );
}