// ACTION TYPES
export const TYPES = {
  OPEN_TWO_FACTOR_AUTH: "OPEN_TWO_FACTOR_AUTH",
  CLOSE_TWO_FACTOR_AUTH: "CLOSE_TWO_FACTOR_AUTH"
};

// ACTION CREATORS
export const openTwoFactorAuthAC = () => ({ type: TYPES.OPEN_TWO_FACTOR_AUTH });
export const closeTwoFactorAuthAC = () => ({ type: TYPES.CLOSE_TWO_FACTOR_AUTH });

// REDUCER
const initialState = {
  isTwoFactorAuthDrawerOpened: false
};

export const widgetTwoFactorAuth = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_TWO_FACTOR_AUTH:
      return {
        ...state,
        isTwoFactorAuthDrawerOpened: true
      };
    case TYPES.CLOSE_TWO_FACTOR_AUTH:
      return {
        ...state,
        isTwoFactorAuthDrawerOpened: false
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsTwoFactorAuthOpened = state =>
  state.widgetTwoFactorAuth.isTwoFactorAuthDrawerOpened;
