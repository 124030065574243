// Result 11/23/2018
export function formatToDateShort(date) {
  return date
    ? new Date(date).toLocaleDateString("en-US", {
      month: "numeric",
      year: "numeric",
      day: "numeric"
    })
    : "";
}

// Result Fri Nov 23 2018
export function formatToDateString(date) {
  return date ? new Date(date).toDateString() : "";
}

export function formatToDateTimeString(date) {
  if (!date) {
    return "";
  }
  const date2 = new Date(date);
  return `${date2.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
    day: "numeric"
  })} ${date2.toLocaleTimeString("en-US")}`;
}

/**
 * Return first day of the year in string format
 *
 * @param {Date} date
 * @returns {string} - example '2019-01-01'
 */
export function getFirstDayOfYear(date) {
  const year = date.getFullYear();
  return `${year}-01-01`;
}

/**
 * Return last day of the year in string format
 *
 * @param {Date} date
 * @returns {string} - example '2019-12-31'
 */
export function getLastDayOfYear(date) {
  const year = date.getFullYear();
  return `${year}-12-31`;
}

/**
 * Returns first day of the quarter in string format
 *
 * @param {Date} date
 * @returns {string}
 */
export function getFirstDayOfQuarter(date) {
  const month = date.getMonth();
  const quarterStartMonth = month - (month % 3);
  const newDate = new Date(date.getTime());
  newDate.setMonth(quarterStartMonth);
  newDate.setUTCDate(1);
  return newDate.toISOString().split("T")[0];
}

/**
 * Returns last day of the quarter if string format
 *
 * @param {Date} date
 * @returns {string}
 */
export function getLastDayOfQuarter(date) {
  const month = date.getMonth();
  const quarterEndMonth = month - (month % 3) + 3;
  const newDate = new Date(date.getTime());
  newDate.setMonth(quarterEndMonth);
  newDate.setUTCDate(0);
  return newDate.toISOString().split("T")[0];
}

export function getHumanizeDate(dateTime, prefix) {
  let humanizeDateText = "";
  let date;
  if (dateTime) {
    let d = new Date(dateTime);
    date = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
    let today = new Date();
    today = today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let hours = d.getHours();
    let meridiem = "pm";
    if (date === today) {
      if (d.getHours() === 0) {
        hours = "12";
        meridiem = "am";
      } else if (d.getHours() < 12) {
        meridiem = "am";
      } else if (d.getHours() === 12) {
        hours = "12";
      } else {
        hours = d.getHours() - 12;
      }
      if (prefix) {
        humanizeDateText = "today ";
      }
      else {
        humanizeDateText = "Today ";
      }
      humanizeDateText =
        humanizeDateText + hours + ":" + String(d.getMinutes()).padStart(2, "0") + " " + meridiem;
    } else {
      humanizeDateText = humanizeDateText + date;
    }
  }
  return (prefix || "") + " " + humanizeDateText;
}
