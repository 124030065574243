// ACTION TYPES
export const TYPES = {
  OPEN_ESS_INVITE_EMAIL_MODAL: "OPEN_ESS_INVITE_EMAIL_MODAL",
  CLOSE_ESS_INVITE_EMAIL_MODAL: "CLOSE_ESS_INVITE_EMAIL_MODAL"
};

// ACTION CREATORS
export const openEssModal = () => ({ type: TYPES.OPEN_ESS_INVITE_EMAIL_MODAL });
export const closeEssModal = () => ({ type: TYPES.CLOSE_ESS_INVITE_EMAIL_MODAL });

// REDUCER
const initialState = {
  isModalOpened: false
};

export const widgetEssReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_ESS_INVITE_EMAIL_MODAL:
      return {
        ...state,
        isModalOpened: true
      };
    case TYPES.CLOSE_ESS_INVITE_EMAIL_MODAL:
      return {
        ...state,
        isModalOpened: false
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsModalOpened = state => state.widgetEss.isModalOpened;
