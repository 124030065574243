import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { PAGES } from "../../routes/pages";
import { authService } from "../../core";

function InternalServerErrorPage() {
  const handleClickDashboard = () => {
    window.location.replace(PAGES.dashboard.path);
  };

  const handleClickLogout = () => {
    authService.removeToken();
    window.location.replace(PAGES.brandsLogin.path);
  };

  return (
    <Box sx={{ maxWidth: 650, textAlign: "left", margin: "auto", padding: 2 }}>
      <Box
        sx={{
          marginTop: 25,
          fontSize: "1.4rem",
          color: "grey.900",
          fontWeight: "bold"
        }}
      >
        Error 500
      </Box>
      <Box sx={{ marginTop: 4, fontSize: "1.4rem", color: "purpleDark.main" }}>
        <b>Oops</b> something went wrong...
      </Box>
      <Box sx={{ marginTop: 0.5, color: "grey.900" }}>
        Our engineering team has been alerted to this issue, and will work promptly to resolve it
      </Box>
      <Box sx={{ mt: 3.75, gap: 1, display: "flex" }}>
        <Button variant="outlined" onClick={handleClickDashboard}>
          Dashboard
        </Button>
        <Button variant="contained" onClick={handleClickLogout}>
          Logout
        </Button>
      </Box>
    </Box>
  );
}

InternalServerErrorPage.propTypes = {};

export default InternalServerErrorPage;
