import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function UncheckedIcon(props) {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g id="checkbox">
        <g id="Rectangle 87">
          <rect width="18" height="18" rx="2" fill="white" />
          <rect
            x="0.5"
            y="0.5"
            width="17"
            height="17"
            rx="1.5"
            stroke="#6D6979"
            strokeOpacity="0.5"
            fill="none"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default UncheckedIcon;
