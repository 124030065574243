import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { PAGES } from "./pages";

const PageDevextremeEmployees = React.lazy(() => import("../pages/devextreme/employees"));

export const DevextremeRoutes = ({}) => {
  return (
    <Switch>
      <ProtectedRoute
        path={PAGES.devextremeEmployees.path}
        title={PAGES.devextremeEmployees.title}
        permission
        component={PageDevextremeEmployees}
      />
    </Switch>
  );
};
