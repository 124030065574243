import { constants, apiService } from "../core";

const push = async (message, url, errorInfo) => {
  const body = {
    message,
    url,
    errorInfo,
    reactAppVersion: constants.APP_VERSION
  };
  const { data } = await apiService.post("api/log/push", body);
  return data;
};

export const logErrorsApi = {
  push
};
