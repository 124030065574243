import { JL } from "jsnlog";

const logErrorsService = {
  error(message, info) {
    JL().error({ message, info, version: process.env.REACT_APP_VERSION });
  },
  info(message, info) {
    JL().info({ message, info, version: process.env.REACT_APP_VERSION });
  },
  warning(message, info) {
    JL().warn({ message, info, version: process.env.REACT_APP_VERSION });
  },
  setLogLevel(level) {
    JL().setOptions({ level });
  }
};

export default logErrorsService;
