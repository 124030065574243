import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';

export default function Checks({ data }) {
  return (
    <React.Fragment>
      <DataGrid
        height='100%'
        width='100%'
        dataSource={data}
        showBorders={true}>
        <Column dataField="empnum" caption="Employee #" width={130} />
        <Column dataField="divnum" caption="Div #" width={120} />
        <Column dataField="chkCounter" caption="Check counter" width={150} />
        <Column dataField="chK_NUM" caption="Check #" width={130} />
        <Column dataField="chK_TYPE" caption="Type" width={120} />
        <Column dataField="paY_FREQ" caption="Frequency" width={120} />
        <Column dataField="gross" caption="Gross" width={120} />
        <Column dataField="net" caption="Net" width={120} />
      </DataGrid>
    </React.Fragment>
  );
}