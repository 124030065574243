import React from 'react';
import { DataGrid, Column, Editing } from 'devextreme-react/data-grid';
import { apiService, axiosError } from '../../../core';

export default function Deductions(props) {
  const payrollNumber = props.payrollNumber;
  const dataGrid = React.useRef();

  const insertRow = e => {
    dataGrid.current.instance.beginCustomLoading("adding deduction");
    const isCanceled = new Promise(async (resolve, reject) => {
      try {
        await apiService.post(`api/Internal/Payrolls/${payrollNumber}/Deductions`, e.data);
        props.deductionsUpdated();
        resolve(false);
      } catch(err) {
        reject(axiosError.errorDescription(err));
      } finally {
        dataGrid.current.instance.endCustomLoading();
      }
    });
    e.cancel = isCanceled;
  }

  const removeRow = e => {
    dataGrid.current.instance.beginCustomLoading("removing deduction");
    const isCanceled = new Promise(async (resolve, reject) => {
      try {
        await apiService.delete(`api/Internal/Payrolls/${payrollNumber}/Deductions/${e.data.rowGuid}`);
        props.deductionsUpdated();
        resolve(false);
      } catch(err) {
        reject(axiosError.errorDescription(err));
      } finally {
        dataGrid.current.instance.endCustomLoading();
      }
    });
    e.cancel = isCanceled;
  }
  
  return (
    <React.Fragment>
      <DataGrid
        ref={dataGrid}
        dataSource={props.deductions}
        showBorders={true}
        onRowInserting={insertRow}
        onRowRemoving={removeRow}>
        <Editing
          mode="row"
          allowDeleting={true}
          allowAdding={true} />
        <Column dataField="empNum" caption="Employee #" width={130} />
        <Column dataField="checkCounter" caption="Check counter" width={140} />
        <Column dataField="clCode" caption="Code" width={120} />
        <Column dataField="clDept" caption="Department #" width={140} />
        <Column dataField="clAmount" caption="Amount" width={120} />
        <Column dataField="clType" caption="Type" width={120} />
        <Column dataField="divNum" caption="Division #" width={140} />
      </DataGrid>
    </React.Fragment>
  );
}