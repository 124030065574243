import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppBar, Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GridOnIcon from '@mui/icons-material/GridOn';

const drawerWidth = 240;

export default function SuperAdminLayout(props) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    sessionStorage.removeItem('jwtToken');
    history.push('/login');
  }

  const listItemButtonClick = (path) => () => {
    history.push({ pathname: path, search: "" });
  }

  const isSelected = (path) => {
    return location.pathname.startsWith(path);
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }} width="100%">
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Brand's Admin
          </Typography>
          <IconButton
            size="large"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem disabled>Login Name: {user?.loginName}</MenuItem> */}
            {/* <MenuItem onClick={handleLogout}>Logout</MenuItem>
                          <Divider />
                          <MenuItem onClick={ListItemButtonClick("/Logs")}>Logs</MenuItem>
                          <MenuItem onClick={ListItemButtonClick("/Users")}>Users</MenuItem> */}
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant={drawerOpen ? "permanent" : "temporary"}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
        open={drawerOpen}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItemButton key='payroll' onClick={listItemButtonClick("/internal/super_admin/payroll")} selected={isSelected('/internal/super_admin/payroll')}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary='Payrolls' />
            </ListItemButton>

            <ListItemButton key='powerGrid' onClick={listItemButtonClick("/internal/super_admin/powerGrids")} selected={isSelected('/internal/super_admin/powerGrids')}>
              <ListItemIcon>
                <GridOnIcon />
              </ListItemIcon>
              <ListItemText primary='Power Grids' />
            </ListItemButton>

            <ListItemButton key='pipMsgTypes' onClick={listItemButtonClick("/internal/super_admin/pipMsgTypes")}
              selected={isSelected('/internal/super_admin/pipMsgTypes')}>
              <ListItemIcon>
                <GridOnIcon />
              </ListItemIcon>
              <ListItemText primary='PIP MsgTypes' />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      <Box component="main" id="main_layout_box" sx={{
        flexGrow: 1,
        p: 1,
        pt: 9,
        width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
        height: '100%'
      }} >
        {props.children}
      </Box>
    </Box>
  );
}

