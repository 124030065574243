import React, { useEffect, useRef } from "react";
import {
  DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
  Paging, SearchPanel, Sorting, Toolbar, Item,
  GroupPanel,
  Grouping
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { Box } from "@mui/system";
import { apiService, axiosError } from "../../../core";
import { useSnackbar } from "notistack";
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";

export default function CheckPayrollIssues({payrollNumber}) {
  const dataGrid = useRef();
  const [issues, setIssues] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getPayrollIssues = async () => {
    dataGrid.current.instance.beginCustomLoading();
    try {
      const response = await apiService.get(`api/Internal/Payrolls/${payrollNumber}/Issues`);
      setIssues(response?.data);
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    }
    dataGrid.current.instance.endCustomLoading();
  };

  useEffect(() => {
    getPayrollIssues();
  }, [payrollNumber]);

  const refreshIssues = () => {
    getPayrollIssues();
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }} id="check_payroll_issues_tab">
      <DataGrid
        dataSource={issues}
        ref={dataGrid}
        height="100%"
        width="100%"
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        showBorders={true}
        columnResizingMode={"widget"}
        showColumnLines={false}
        rowAlternationEnabled
        focusedRowEnabled
        autoNavigateToFocusedRow
        keyExpr={['empNum', 'chkCounter', 'msgType']}
      >
        <Toolbar>
          <Item location="before">
            <Button icon="refresh" onClick={refreshIssues} />
          </Item>
          <Item name="groupPanel" locateInMenu="auto" location="before" />
          <Item name="columnChooserButton" locateInMenu="auto" location="after" />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <SearchPanel visible width={250} />
        <HeaderFilter visible allowSearch />
        <ColumnChooser enabled />
        <Sorting mode="multiple" />
        <ColumnFixing />
        <Paging defaultPageSize={40} />
        <Pager showPageSizeSelector />
        <FilterPanel visible />
        <FilterBuilderPopup />
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={true} />
        {/* <Column dataField="conum" allowEditing={false}/>
        <Column dataField="prnum" caption="Pr#" defaultSortIndex={0} defaultSortOrder="desc" allowEditing={false}/>
        <Column dataField="gross" dataType="number" format="currency" />
        <Column dataField="net" dataType="number" format="currency" />
        <Column dataField="checK_DATE" dataType="datetime" />
        <Column dataField="pyarollStatus">
          <Lookup dataSource={payrollStatuses} />
        </Column>
        <Column dataField="pR_DESCR" />
        <Column dataField="rowGuid" visible={false} />
        <Column dataField="firstPeriodStartDate" dataType="datetime" />
        <Column dataField="firstPeriodEndDate" dataType="datetime" />
        <Column dataField="secondPeriodStartDate" dataType="datetime" />
        <Column dataField="secondPeriodEndDate" dataType="datetime" />
        <Column dataField="thirdPeriodStartDate" dataType="datetime" />
        <Column dataField="thirdPeriodEndDate" dataType="datetime" /> */}
      </DataGrid>
    </Box>
  );
}
