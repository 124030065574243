import React, { useState, useEffect, useRef } from "react";
import {
    DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
    Paging, SearchPanel, Sorting, Column, Toolbar, Item, Editing, Form, Popup
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { Box } from "@mui/system";
import { urlHelpers } from "../../../core";

const notesEditorOptions = { height: 100 };

export default function PipMsgTypes(props) {
    const [remoteDataSource, setRemoteDataSource] = useState();
    const [focusedRowPrNum, setFocusedRowPrNum] = useState();
    const dataGrid = useRef();

    useEffect(() => {
        const getPayrolls = async () => {
            setRemoteDataSource(
                createStore({
                    key: "msgType",
                    loadUrl: urlHelpers.getAbsoluteURL(`api/Internal/PayrollsInProcessMsgTypes`),
                    updateUrl: urlHelpers.getAbsoluteURL(`api/Internal/PayrollsInProcessMsgTypes`),
                    insertUrl: urlHelpers.getAbsoluteURL(`api/Internal/PayrollsInProcessMsgTypes`),
                    onBeforeSend: (e, s) => {
                        s.headers = {
                            Authorization: `Bearer ${localStorage.getItem("BrandsWebJWT")}`
                        };
                    }
                })
            );
        };

        getPayrolls();
    }, []);

    const onFocusedRowChanging = e => {
        setFocusedRowPrNum(e.row && e.row.data?.prnum);
    };

    const refreshPayrolls = () => {
        dataGrid.current.instance.refresh()
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <DataGrid remoteOperations
                ref={dataGrid}
                dataSource={remoteDataSource}
                height="100%"
                width="100%"
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                showBorders={true}
                columnResizingMode={"widget"}
                showColumnLines={false}
                rowAlternationEnabled
                focusedRowEnabled
                onFocusedRowChanged={onFocusedRowChanging}
                autoNavigateToFocusedRow
            >
                <Toolbar>
                    <Item location="before">
                        <Button icon="refresh" onClick={refreshPayrolls} />
                    </Item>
                    <Item name="columnChooserButton" locateInMenu="auto" location="after" />
                    <Item name="revertButton" locateInMenu="auto" location="after" />
                    <Item name="saveButton" locateInMenu="auto" location="after" />
                    <Item name="searchPanel" locateInMenu="auto" location="after" />
                </Toolbar>
                <SearchPanel visible width={250} />
                <HeaderFilter visible allowSearch />
                <ColumnChooser enabled />
                <Sorting mode="multiple" />
                <ColumnFixing />
                <Paging defaultPageSize={40} />
                <Pager showPageSizeSelector />
                <FilterPanel visible />
                <FilterBuilderPopup />

                <Editing mode="popup" allowUpdating allowAdding>
                    <Popup title="PayrollsInProcessMsgTypes" showTitle={true} width={850} height='80vh'/>
                    <Form>
                        <Item itemType="group" colCount={3} colSpan={2}>
                            <Item dataField="msgType" />
                            <Item dataField="allowToBeIgnored" />
                            <Item dataField="allowIfNotEnforced" />
                            <Item dataField="description" />
                            <Item dataField="note" />
                            <Item dataField="parent" />                          
                        </Item>

                        <Item itemType="group" caption="Paydeck" colCount={1} colSpan={2}>
                            <Item dataField="showInPaydeck" editorType='dxCheckBox'/>
                            <Item dataField="allowToBeIgnoredInPaydeck" editorType='dxCheckBox'/>
                            <Item dataField="showInPayrollEntry" editorType='dxCheckBox'/>
                            <Item dataField="showOnlyIfPayrollIsValidated" editorType='dxCheckBox'/>
                            <Item dataField="hasAction" />
                            <Item dataField="actionButtonLabel" />
                            <Item dataField="clientTitle" colSpan={4}/>
                            <Item
                                dataField="paydeckMessageFormat"
                                // editorType="dxTextArea"
                                editorOptions={notesEditorOptions} />
                        </Item>
                    </Form>
                </Editing>

                <Column type='buttons'>
                    <Button name='edit' />
                    <Button name='delete' />
                    <Button name='save' />
                    <Button name='cancel' />
                </Column>

                <Column dataField="msgType" allowEditing={false} />
                <Column dataField="allowToBeIgnored" />
                <Column dataField="allowIfNotEnforced" />
                <Column dataField="showInPaydeck" />
                <Column dataField="showInPayrollEntry" />

                <Column dataField="allowToBeIgnoredInPaydeck" />
                <Column dataField="showOnlyIfPayrollIsValidated" />
                <Column dataField="hasAction" />
                <Column dataField="actionButtonLabel" />
                <Column dataField="description" />
                <Column dataField="note" />
                <Column dataField="clientTitle" />
                <Column dataField="paydeckMessageFormat" />
                <Column dataField="parent" />

            </DataGrid>
        </Box>
    );
}
