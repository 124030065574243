import { nanoid } from "nanoid";

import { constants } from "./index";
import { logErrorsService } from "../core";

const setJwtToken = (token) => {
  localStorage.setItem(constants.JWT_TOKEN, token);
};
const getJwtToken = () => localStorage.getItem(constants.JWT_TOKEN);
const removeJwtToken = () => {
  localStorage.removeItem(constants.JWT_TOKEN);
};

const clear = () => {
  localStorage.clear();
};

const setCompanyId = (conum) => {
  localStorage.setItem("companyId", conum);
};
const getCompanyId = () => {
  const companyIdStr = localStorage.getItem("companyId");
  if (companyIdStr) {
    return parseInt(companyIdStr, 10);
  } else {
    return null;
  }
};

const setEmployeeNumber = (empnum) => {
  localStorage.setItem("employeeNumber", empnum);
};

const setVersion = (version) => {
  localStorage.setItem("version", version);
};
const getVersion = () => localStorage.getItem("version") || "";

const setDownloadCount = (downloadCount) => {
  localStorage.setItem("downloadCount", downloadCount);
};
const getDownloadCount = () => localStorage.getItem("downloadCount") || 0;
const incrementDownloadCount = () => {
  const currentValue = parseInt(getDownloadCount(), 10);
  setDownloadCount(currentValue + 1);
};

const checkVersion = (version) => {
  setTimeout(function () {
    const prevVersion = localStorage.getItem("version");
    localStorage.setItem("version", version);
    logErrorsService.info(`In checkVersion. prevVersion: ${prevVersion} version: ${version}`);
    if (prevVersion && version && prevVersion !== version) {
      logErrorsService.info("New version available, refreshing site");
      console.log("New version available, refreshing site");
      window.location.reload();
    } else {
      console.log("Version up to date");
    }
  }, 750);
};

const setGuid = (id) => {
  localStorage.setItem(constants.GUID, id);
};

const getGuid = () => localStorage.getItem(constants.GUID);

const removeGuid = () => localStorage.removeItem(constants.GUID);

const setDontShowReportConfirm = () => {
  localStorage.setItem(constants.DONT_SHOW_REPORT_CONFIRM, "true");
};

const getDontShowReportConfirm = () => localStorage.getItem(constants.DONT_SHOW_REPORT_CONFIRM);

const setRememberBrowserGuid = (guid) => {
  localStorage.setItem(constants.REMEMBER_BROWSER_GUID, guid);
};
const getRememberBrowserGuid = () => {
  const rememberBrowserGuid = localStorage.getItem(constants.REMEMBER_BROWSER_GUID);
  return rememberBrowserGuid ?? null;
};
const removeRememberBrowserGuid = () => localStorage.removeItem(constants.REMEMBER_BROWSER_GUID);

const getOrSetBfp = () => {
  const bfp = localStorage.getItem(constants.BFP);
  if (bfp) return bfp;
  const guid = nanoid();
  localStorage.setItem(constants.BFP, guid);
  return guid;
};

const setEmployeesTableColumnModel = (model) => {
  localStorage.setItem(constants.EMPLOYEE_TABLE_COLUMNS_MODEL, model);
};

const getEmployeesTableColumnModel = () => {
  try {
    const data = localStorage.getItem(constants.EMPLOYEE_TABLE_COLUMNS_MODEL);
    const columnVisibilityModel = JSON.parse(data);
    return columnVisibilityModel;
  } catch (err) {
    return;
  }
};

const getLiveReportsColumns = (reportId) => {
  try {
    const data = localStorage.getItem(`${constants.LIVE_REPORTS_COLUMNS}_${reportId}`);
    const liveReportsColumns = JSON.parse(data);
    return liveReportsColumns ?? [];
  } catch (err) {
    return [];
  }
};

const setLiveReportsColumns = (reportId, columns) => {
  localStorage.setItem(`${constants.LIVE_REPORTS_COLUMNS}_${reportId}`, JSON.stringify(columns));
};

const getLiveReportsGridLayout = (reportId) => {
  try {
    const data = localStorage.getItem(`${constants.LIVE_REPORTS_GRID_LAYOUT}_${reportId}`);
    const liveReportsColumns = JSON.parse(data);
    return liveReportsColumns ?? [];
  } catch (err) {
    return [];
  }
};

const setLiveReportsGridLayout = (reportId, data) => {
  localStorage.setItem(`${constants.LIVE_REPORTS_GRID_LAYOUT}_${reportId}`, JSON.stringify(data));
};

export const localStorageSyncApi = {
  setJwtToken,
  getJwtToken,
  removeJwtToken,
  clear,
  setCompanyId,
  getCompanyId,
  setEmployeeNumber,
  setVersion,
  getVersion,
  setDownloadCount,
  getDownloadCount,
  incrementDownloadCount,
  checkVersion,
  setGuid,
  getGuid,
  removeGuid,
  setDontShowReportConfirm,
  getDontShowReportConfirm,
  setRememberBrowserGuid,
  getRememberBrowserGuid,
  removeRememberBrowserGuid,
  getOrSetBfp,
  setEmployeesTableColumnModel,
  getEmployeesTableColumnModel,
  getLiveReportsColumns,
  setLiveReportsColumns,
  getLiveReportsGridLayout,
  setLiveReportsGridLayout,
};
