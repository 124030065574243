import { jwtDecode } from "jwt-decode";
import { localStorageSyncApi } from "./localStorageSyncApi";
import { sessionStorageSyncApi } from "./sessionStorageSyncApi";
import { REDIRECT_TO } from "../utils/constants";
import logErrorsService from "./logErrorsService";
import { store } from "../redux/configureStore";
import { showSnackbar } from "../redux/ducks/snackbarDuck";

const authService = {
  saveToken(token) {
    try {
      logErrorsService.info("saveToken()", token);
      localStorageSyncApi.setJwtToken(token);
      sessionStorageSyncApi.setJwtToken(token);
    } catch (ex) {
      logErrorsService.error(ex, "error in saveToken()");
    }
  },
  saveJwtGuid(jwtGuid) {
    sessionStorageSyncApi.setJwtGuid(jwtGuid);
  },
  removeToken() {
    logErrorsService.info("removeToken");
    sessionStorageSyncApi.removeJwtToken();
    localStorageSyncApi.removeJwtToken();
  },
  getToken() {
    let token;
    token = sessionStorageSyncApi.getJwtToken();
    if (!token) token = localStorageSyncApi.getJwtToken();
    return token;
  },
  getJwtGuid() {
    let jwtGuid = sessionStorageSyncApi.getJwtGuid();
    return jwtGuid;
  },
  getDecodedToken() {
    const token = this.getToken();
    if (!token || token === "undefined") {
      return undefined;
    }
    try {
      let decoded = jwtDecode(token);
      return decoded;
    } catch (ex) {
      //logErrorsApi.push(`Error in jwtDecode - ${ex.message}`);
      return undefined;
    }
  },
  isTokenExpired() {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return true;
    }
    const expirationTime = decodedToken.exp * 1000;
    if (!window.serverTimeOffset) {
      return expirationTime < Date.now();
    }
    const serverTime = Date.now() + (window.serverTimeOffset ?? 0);
    const expired = expirationTime < serverTime;
    if (
      expired &&
      !window.location.href?.endsWith("/login") &&
      !window.location.href?.endsWith("onboarding/new")
    ) {
      store.dispatch(
        showSnackbar({
          variant: "failure",
          message:
            "Your access token has expired. Please login again. If the issue persists, check the date and time on your device to ensure they are correct.",
        })
      );
    }
    return expired;
  },
  getTokenClaim(claimName) {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return null;
    }
    return decodedToken[claimName];
  },
  getMillisecondsTillExpiration() {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return 0;
    }
    const currentTimeMs = Date.now();
    const expirationTimeMs = decodedToken.exp * 1000;
    return expirationTimeMs > currentTimeMs ? expirationTimeMs - currentTimeMs : 0;
  },
  isUserAuthenticated() {
    var token = this.getToken();
    if (!token) return false;
    if (this.isTokenExpired()) return false;
    let userId = this.getTokenClaim("UserId");
    return userId;
  },
  logToken() {
    const token = this.getToken();
    if (!token) return false;
    if (this.isTokenExpired()) {
      logErrorsService.warning("token expired.", {
        token: localStorageSyncApi.getJwtToken(),
        sessionToken: sessionStorageSyncApi.getJwtToken(),
        clientDateTime: new Date().toString(),
        clientUtcDateTime: new Date().toUTCString(),
        serverTimeOffset: window.serverTimeOffset,
      });
      return false;
    }
    let userId = this.getTokenClaim("UserId");
    logErrorsService.info("token user id:", userId);
    return userId;
  },
  isCompanyAuthenticated() {
    var token = this.getToken();
    if (!token) return false;
    return !this.isTokenExpired() && this.getTokenClaim("BrandsPay_Conum");
  },
  isIpRestricted() {
    let isUserAuthenticated = this.isUserAuthenticated();
    let redirectTo = this.getTokenClaim("RedirectTo");
    const isIpRestricted = isUserAuthenticated && redirectTo === REDIRECT_TO.ipRestricted;
    return isIpRestricted;
  },
  isCpaUser() {
    let isUserAuthenticated = this.isUserAuthenticated();
    let cpaFirmId = this.getTokenClaim("CpaFirmId");
    let redirectTo = this.getTokenClaim("RedirectTo");
    let results =
      !!isUserAuthenticated &&
      (!!cpaFirmId ||
        redirectTo == REDIRECT_TO.addCpaUserName ||
        redirectTo == REDIRECT_TO.addCpaDetails);
    return results;
  },
  isEssUser() {
    let isUserAuthenticated = this.isUserAuthenticated();
    let essProfileId = this.getTokenClaim("EssProfileId");
    let essOnboardingProfileId = this.getTokenClaim("EssOnboardingProfileId");
    return !!isUserAuthenticated && (!!essProfileId || !!essOnboardingProfileId);
  },
  isRedirectToSet(redirectTo) {
    const isUserAuthenticated = this.isUserAuthenticated();
    const redirectToClaim = this.getTokenClaim("RedirectTo");
    const isRedirectToSet =
      isUserAuthenticated && parseInt(redirectToClaim) === parseInt(redirectTo);
    return isRedirectToSet;
  },
  signOut(currentHistory, callback) {
    this.removeToken();
    if (callback) {
      callback();
    } else if (currentHistory) {
      currentHistory.push("/auth/login");
    }
  },
  authenticate(currentHistory) {
    if (!this.isCompanyAuthenticated()) {
      currentHistory.push("/login");
    }
  },
  getImpersonatedMessage() {
    const impersonatingUser = this.getTokenClaim("IUserId");
    const impersonatingCompany = this.getTokenClaim("IConum");
    if (impersonatingUser) return "Impersonating User";
    if (impersonatingCompany) return "Impersonating Company";
    return;
  },
};

export default authService;
