import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import { PAGES } from "./pages";
import { Switch } from "react-router-dom";

/**
 * CPA routes -> /cpa
 */
const PageCPAPortalClients = React.lazy(() => import("../pages/cpa/clients"));
const PageCPAPortalProfile = React.lazy(() => import("../pages/cpa/profile"));
const PageCPAAddClient = React.lazy(() => import("../pages/cpa/add"));
const PageCPAPortalSettings = React.lazy(() => import("../pages/cpa/settings/[section]"));
const PageCPAPortalSignup = React.lazy(() => import("../pages/cpa/signup/[firmId]"));
const PageCPAPortalOverview = React.lazy(() => import("../pages/cpa/overview/[companyId]"));
const PageCPAPortalReports = React.lazy(() => import("../pages/cpa/reports/[section]/[companyId]"));

export const CpaRoutes = ({ selectedCpaFirmId, permission }) => {
    return <Switch>
        <ProtectedRoute
            path={`${PAGES.cpaClientOverview.path}/:companyId`}
            title={PAGES.cpaClientOverview.title}
            permission={true}
            component={PageCPAPortalOverview}
            disabled={!selectedCpaFirmId}
        />
        <ProtectedRoute
            path={PAGES.cpaClients.path}
            title={PAGES.cpaClients.title}
            permission={true}
            component={PageCPAPortalClients}
            disabled={!selectedCpaFirmId}
        />
        <ProtectedRoute
            path={`${PAGES.cpaSignup.path}/:firmId`}
            title={PAGES.cpaSignup.title}
            permission={true}
            component={PageCPAPortalSignup}
            disabled={!selectedCpaFirmId}
        />
        <ProtectedRoute
            path={PAGES.cpaProfile.path}
            title={PAGES.cpaProfile.title}
            permission={true}
            component={PageCPAPortalProfile}
            disabled={!selectedCpaFirmId}
        />
        <ProtectedRoute
            path={`${PAGES.cpaSettings.path}/:section`}
            title={PAGES.cpaSettings.title}
            permission={true}
            component={PageCPAPortalSettings}
            disabled={!selectedCpaFirmId}
        />
        <ProtectedRoute
            path={`${PAGES.cpaReports.path}/:section/:companyId/:sectionId/:reportId`}
            title={PAGES.cpaReports.title}
            permission={true}
            component={PageCPAPortalReports}
            disabled={!selectedCpaFirmId}
        />
        <ProtectedRoute
            path={`${PAGES.cpaReports.path}/:section/:companyId/:sectionId`}
            title={PAGES.cpaReports.title}
            permission={true}
            component={PageCPAPortalReports}
            disabled={!selectedCpaFirmId}
        />
        <ProtectedRoute
            path={`${PAGES.cpaReports.path}/:section/:companyId`}
            title={PAGES.cpaReports.title}
            permission={true}
            component={PageCPAPortalReports}
            disabled={!selectedCpaFirmId}
        />
        <ProtectedRoute
            path={PAGES.cpaAddCompany.path}
            title={PAGES.cpaAddCompany.title}
            permission={true}
            component={PageCPAAddClient}
            disabled={!selectedCpaFirmId}
        />
    </Switch>
}