import React, { useState, useEffect, useRef } from "react";
import {
  DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
  Paging, SearchPanel, Sorting, Toolbar, Item, Column, LoadPanel
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { apiService, axiosError } from "../../../core";
import { useSnackbar } from "notistack";
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";

export default function CheckPayrollIssuesLog({ payrollNumber }) {
  const dataGrid = React.useRef();
  const [issuesLog, setIssuesLog] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getIssuesLog = async () => {
    dataGrid.current.instance.beginCustomLoading();
    try {
      const response = await apiService.get(`api/Internal/Payrolls/${payrollNumber}/IssuesLog`);
      setIssuesLog(response?.data);
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    }
    dataGrid.current.instance.endCustomLoading();
  };

  useEffect(() => {
    getIssuesLog();
  }, [payrollNumber]);

  const refreshIssuesLog = () => {
    getIssuesLog();
  }

  return (
    <React.Fragment>
      <DataGrid
        ref={dataGrid}
        dataSource={issuesLog}
        height='100%'
        width='100%'
        showBorders={true}
        allowColumnResizing
        allowColumnReordering>
        <Toolbar>
          <Item location="before">
            <Button icon="refresh" onClick={refreshIssuesLog} />
          </Item>
          <Item name="columnChooserButton" locateInMenu="auto" location="after" />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <SearchPanel visible width={250} />
        <HeaderFilter visible allowSearch />
        <ColumnChooser enabled />
        <Sorting mode="multiple" />
        <ColumnFixing />
        <Paging defaultPageSize={40} />
        <Pager showPageSizeSelector />
        <FilterPanel visible />
        <FilterBuilderPopup />
        <Column dataField="empNum" caption="Employee #" />
        <Column dataField="chkCounter" caption="Check counter" />
        <Column dataField="date" caption="Date" dataType="datetime" />
        <Column dataField="currentPayrollStatus" caption="Current payroll status" />
        <Column dataField="msgType" caption="Msg type" />
        <Column dataField="msgBody" caption="Msg body" />
        <Column dataField="priority" caption="Priority" />
      </DataGrid>
    </React.Fragment>
  );
}