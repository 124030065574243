import React, { useState, useEffect, useRef } from "react";
import {
  DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
  Paging, SearchPanel, Sorting, Column, Toolbar, Item, Lookup, Editing, MasterDetail
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { Box } from "@mui/system";
import { urlHelpers } from "../../../core";

export default function CheckMasterBatch({payrollNumber}) {
  const [remoteDataSource, setRemoteDataSource] = useState();
  const [focusedRowPrNum, setFocusedRowPrNum] = useState();
  const dataGrid = useRef();

  useEffect(() => {
    const getPayrolls = async () => {
      setRemoteDataSource(
        createStore({
          key: "id",
          loadUrl: urlHelpers.getAbsoluteURL(`api/Internal/Payrolls/${payrollNumber}/CheckMasterBatches`),
          updateUrl: urlHelpers.getAbsoluteURL(`api/Internal/Payrolls/${payrollNumber}/CheckMasterBatches`),
          onBeforeSend: (e, s) => {
            s.headers = {
              Authorization: `Bearer ${localStorage.getItem("BrandsWebJWT")}`
            };
          }
        })
      );
    };

    getPayrolls();
  }, [payrollNumber]);

  const onFocusedRowChanging = e => {
    setFocusedRowPrNum(e.row && e.row.data?.prnum);
  };

  const refreshPayrolls = () => {
    dataGrid.current.instance.refresh()
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <DataGrid
        ref={dataGrid}
        dataSource={remoteDataSource}
        height="100%"
        width="100%"
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        showBorders={true}
        columnResizingMode={"widget"}
        showColumnLines={false}
        rowAlternationEnabled
        focusedRowEnabled
        onFocusedRowChanged={onFocusedRowChanging}
        autoNavigateToFocusedRow
      >
        <Toolbar>
          <Item location="before">
            <Button icon="refresh" onClick={refreshPayrolls} />
          </Item>
          <Item name="columnChooserButton" locateInMenu="auto" location="after" />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <Editing mode="cell" allowDeleting={false} allowUpdating={true} />
        <SearchPanel visible width={250} />
        <HeaderFilter visible allowSearch />
        <ColumnChooser enabled />
        <Sorting mode="multiple" />
        <ColumnFixing />
        <Paging defaultPageSize={40} />
        <Pager showPageSizeSelector />
        <FilterPanel visible />
        <FilterBuilderPopup />

        {/* <Column dataField="conum" allowEditing={false}/>
        <Column dataField="prnum" caption="Pr#" defaultSortIndex={0} defaultSortOrder="desc" allowEditing={false}/>
        <Column dataField="gross" dataType="number" format="currency" />
        <Column dataField="net" dataType="number" format="currency" />
        <Column dataField="checK_DATE" dataType="datetime" />
        <Column dataField="pyarollStatus">
          <Lookup dataSource={payrollStatuses} />
        </Column>
        <Column dataField="pR_DESCR" />
        <Column dataField="rowGuid" visible={false} />
        <Column dataField="firstPeriodStartDate" dataType="datetime" />
        <Column dataField="firstPeriodEndDate" dataType="datetime" />
        <Column dataField="secondPeriodStartDate" dataType="datetime" />
        <Column dataField="secondPeriodEndDate" dataType="datetime" />
        <Column dataField="thirdPeriodStartDate" dataType="datetime" />
        <Column dataField="thirdPeriodEndDate" dataType="datetime" /> */}
      </DataGrid>
    </Box>
  );
}
