import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function RadioButtonIcon(props) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <rect width="20" height="20" rx="10" fill="white" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#6D6979" strokeOpacity="0.5" />
    </SvgIcon>
  );
}

export default RadioButtonIcon;
