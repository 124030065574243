import { nanoid } from "nanoid";

import { constants } from "./index";

const setJwtGuid = (jwtGuid) => {
  sessionStorage.setItem(constants.JWT_GUID, jwtGuid);
};

const getJwtGuid = () => sessionStorage.getItem(constants.JWT_GUID);

const setJwtToken = (token) => {
  sessionStorage.setItem(constants.JWT_TOKEN, token);
};

const getJwtToken = () => sessionStorage.getItem(constants.JWT_TOKEN);

const removeJwtToken = () => {
  sessionStorage.removeItem(constants.JWT_TOKEN);
};

const getEmployeeId = () => sessionStorage.getItem(constants.EMPLOYEE_ID);

const setEmployeeId = (conum, empnum) => {
  sessionStorage.setItem(constants.EMPLOYEE_ID, JSON.stringify({ conum, empnum }));
};

const removeEmployeeId = () => {
  sessionStorage.removeItem(constants.EMPLOYEE_ID);
};

const getOrSetSfp = () => {
  const sfp = sessionStorage.getItem(constants.SFP);
  if (sfp) return sfp;
  const guid = nanoid();
  sessionStorage.setItem(constants.SFP, guid);
  return guid;
};

const setIsNative = () => {
  sessionStorage.setItem(constants.IS_NATIVE, true);
};

const getIsNative = () => sessionStorage.getItem(constants.IS_NATIVE) === "true";

export const sessionStorageSyncApi = {
  setJwtGuid,
  getJwtGuid,
  setJwtToken,
  getJwtToken,
  removeJwtToken,
  getEmployeeId,
  setEmployeeId,
  removeEmployeeId,
  getOrSetSfp,
  setIsNative,
  getIsNative,
};
