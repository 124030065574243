import { PAGES } from "../../routes/pages";
import { UserProfile } from "../../types/Profile"; // Import the UserProfile type

// ACTION TYPES
export const TYPES = {
  LOADING_START: "LOADING_START",
  LOADING_FINISH: "LOADING_FINISH",

  // SET CURRENT TAB
  SET_REPORTS_SUBNAVBAR_TAB: "SET_REPORTS_SUBNAVBAR_TAB",
  SET_EMPLOYEES_SUBNAVBAR_TAB: "SET_EMPLOYEES_SUBNAVBAR_TAB",
  SET_USERS_SUBNAVBAR_TAB: "SET_USERS_SUBNAVBAR_TAB",

  SET_HELP_CENTER_LINK: "SET_HELP_CENTER_LINK",

  SET_CURRENT_COMPANY_ID: "SET_CURRENT_COMPANY_ID",
  SET_IS_BANNER_VISIBLE: "SET_IS_BANNER_VISIBLE",
  SET_BANNER_HEIGHT: "SET_BANNER_HEIGHT",

  SET_PERMISSIONS: "SET_PERMISSIONS",

  SET_PROFILE: "SET_PROFILE",

  SET_MAIN_DRAWER_OPEN: "SET_MAIN_DRAWER_OPEN",

  SET_CPA_FIRMS: "SET_CPA_FIRMS",
  SET_CPA_CLIENT: "SET_CPA_CLIENT",

  SET_POWER_IMPORT_DOWNLOAD: "SET_POWER_IMPORT_DOWNLOAD",

  UPDATE_PROFILE: "UPDATE_PROFILE",

  TOGGLE_IS_IFRAME_LOADED: "TOGGLE_IS_IFRAME_LOADED",
};

// ACTION CREATORS
export const loadingStartAC = () => ({ type: TYPES.LOADING_START });

export const loadingFinishAC = () => ({ type: TYPES.LOADING_FINISH });

export const setHelpCenterLinkAC = (helpCenterLink) => ({
  type: TYPES.SET_HELP_CENTER_LINK,
  payload: {
    helpCenterLink,
  },
});

export const setIsBannerVisible = (isBannerVisible) => {
  return {
    type: TYPES.SET_IS_BANNER_VISIBLE,
    payload: {
      isBannerVisible,
    },
  };
};

export const setBannerHeight = (bannerHeight) => {
  return {
    type: TYPES.SET_BANNER_HEIGHT,
    payload: {
      bannerHeight,
    },
  };
};


export const setPermissions = (permissions) => ({
  type: TYPES.SET_PERMISSIONS,
  payload: {
    permissions,
  },
});

export const setProfile = (profile: UserProfile) => ({
  type: TYPES.SET_PROFILE,
  payload: {
    profile,
  },
});

export const setMainDrawerOpenAC = (isDrawerOpen) => ({
  type: TYPES.SET_MAIN_DRAWER_OPEN,
  payload: {
    isDrawerOpen,
  },
});

export const setCpaFirms = (cpa) => ({
  type: TYPES.SET_CPA_FIRMS,
  payload: {
    cpa,
  },
});

export const setCpaClient = (cpaClient) => ({
  type: TYPES.SET_CPA_CLIENT,
  payload: {
    cpaClient,
  },
});

export const setPowerImportDownload = (powerImportDownload) => ({
  type: TYPES.SET_POWER_IMPORT_DOWNLOAD,
  payload: {
    powerImportDownload,
  },
});

export const updatProfile = (profile) => ({
  type: TYPES.UPDATE_PROFILE,
  payload: {
    profile,
  },
});

export const toggleIsIframeLoaded = (isIFrameLoaded) => ({
  type: TYPES.TOGGLE_IS_IFRAME_LOADED,
  payload: {
    isIFrameLoaded,
  },
});

// REDUCER
const initialState = {
  loading: false,
  reportsSubnavbarTab: "",
  usersSubnavbarTab: PAGES.settingsUsers.id,
  helpCenterLink: "https://support.brandspaycheck.com/hc/en-us",
  currentCompanyId: 0,
  permissions: null,
  isBannerVisible: false,
  bannerHeight: 0,
  profile: null,
  initiated: false,
  isMainDrawerOpen: true,
  subMenuTabs: {
    reportBrowser: {
      displayName: "Run a Report",
      order: 1,
    },
    downloads: {
      displayName: "Downloads",
      order: 2,
    },
    reportLibrary: {
      displayName: "Reports Archive",
      order: 3,
    },
  },
  subNavUsersTabs: {
    settingsUsers: {
      displayName: "Manage Users",
      order: 1,
    },
    settingsBankAccounts: {
      displayName: "Bank Account(s)",
      order: 2,
    },
    settingsIpRestrictions: {
      displayName: "IP Restrictions",
      order: 3,
    },
    settingsNotifications: {
      displayName: "Notifications",
      order: 4,
    },
  },
  cpaClient: null,
  powerImportDownload: false,
  isIFrameLoaded: false,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case TYPES.LOADING_FINISH:
      return {
        ...state,
        loading: false,
      };
    case TYPES.SET_REPORTS_SUBNAVBAR_TAB:
      return {
        ...state,
        reportsSubnavbarTab: action.payload.pageId,
      };
    case TYPES.SET_EMPLOYEES_SUBNAVBAR_TAB:
      return {
        ...state,
        employeesSubnavbarTab: action.payload.pageId,
      };
    case TYPES.SET_USERS_SUBNAVBAR_TAB:
      return {
        ...state,
        usersSubnavbarTab: action.payload.pageId,
      };
    case TYPES.SET_HELP_CENTER_LINK:
      return {
        ...state,
        helpCenterLink: action.payload.helpCenterLink,
      };
    case TYPES.SET_CURRENT_COMPANY_ID:
      return {
        ...state,
        currentCompanyId: action.payload.currentCompanyId,
      };
    case TYPES.SET_IS_BANNER_VISIBLE:
      return {
        ...state,
        isBannerVisible: action.payload.isBannerVisible,
      };
    case TYPES.SET_BANNER_HEIGHT:
      return {
        ...state,
        bannerHeight: action.payload.bannerHeight,
      };
    case TYPES.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    case TYPES.SET_PROFILE:
      if (!action.payload.profile) return { ...state, profile: null, initiated: true };
      return {
        ...state,
        profile: action.payload.profile,
        initiated: true,
        currentCompanyId: action.payload.conum,
      };
    case TYPES.SET_MAIN_DRAWER_OPEN:
      return {
        ...state,
        isMainDrawerOpen: action.payload.isDrawerOpen,
      };
    case TYPES.SET_CPA_FIRMS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.cpa,
        },
      };
    case TYPES.SET_CPA_CLIENT:
      return {
        ...state,
        cpaClient: action.payload.cpaClient,
      };
    case TYPES.SET_POWER_IMPORT_DOWNLOAD:
      return {
        ...state,
        powerImportDownload: action.payload.powerImportDownload,
      };
    case TYPES.UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.profile,
        },
      };
    case TYPES.TOGGLE_IS_IFRAME_LOADED:
      return {
        ...state,
        isIFrameLoaded: action.payload.isIFrameLoaded,
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsLoading = (state) => state.app.loading;

export const selectReportsSubnavbarTab = (state) => state.app.reportsSubnavbarTab;
export const selectEmployeesSubnavbarTab = (state) => state.app.employeesSubnavbarTab;
export const selectUsersSubnavbarTab = (state) => state.app.usersSubnavbarTab;

export const selectHelpCenterLink = (state) => state.app.helpCenterLink;

export const selectSubMenuTabs = (state) => state.app.subMenuTabs;
export const selectSubNavUsersTabs = (state) => state.app.subNavUsersTabs;

export const selectCurrentCompanyId = (state) => state.app.currentCompanyId;

export const selectIsBannerVisible = (state) => state.app.isBannerVisible;
export const selectBannerHeight = (state) => state.app.bannerHeight;

export const selectPermissions = (state) => state.app.permissions;

export const selectProfile = (state) => state.app.profile;

export const selectInitiated = (state) => state.app.initiated;

export const selectIsMainDrawerOpen = (state) => state.app.isMainDrawerOpen;

export const selectCpaClient = (state) => state.app.cpaClient;

export const selectPowerImportDownload = (state) => state.app.powerImportDownload;

export const selectIsIFrameLoaded = (state) => state.app.isIFrameLoaded;
