import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/ducks/appDuck';
import { Button } from '@mui/material';
import { useHistory, useLocation } from "react-router";
import { PAGES } from '../../routes/pages';
import { apiService } from '../../core';

const FrillSso = () => {
    const profile = useSelector(selectProfile);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        //https://github.com/Frill-co/frill-widget-examples?tab=readme-ov-file#identifying-users
        //https://help.frill.co/article/204-adding-custom-user-attributes-for-segmentation
        const getFillSSO = async () => {
            try {
                //const response = await apiService.get("/api/auth/FrillSSO");
                const token = encodeURIComponent(profile.frillSsoToken);
                const params = new URLSearchParams(location.search);
                const ssoRedirect = params.get('redirect') || 'https://1800dev.frill.co';
                const url = `${ssoRedirect}?ssoToken=${token}`;
                console.log(url);
                window.location.href = url;
            } catch (error) {
                console.log(error);
            }
        };
        getFillSSO();
    }, [profile, location.search]);

    if(!profile.frillSsoToken) {
        return (
            <div>
                <h1>Frill SSO</h1>
                <p>You need to be logged into a company to use Frill SSO.</p>
            </div>
        );
    }

    return (
        <div>
            <h1>Frill SSO</h1>
            <p>Frill SSO page content</p>
            <Button onClick={() => {
                history.push(PAGES.brandsLogin.path, { prevLocation: `${location.pathname}${location.search}` });
            }}>Login.</Button>
        </div>
    );
};

export default FrillSso;