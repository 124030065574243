import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CheckMarkIcon = (props) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M12.7514 2.23874C12.3839 1.87124 11.7914 1.87124 11.4239 2.23874L5.19138 8.47124C4.89888 8.76374 4.89888 9.23624 5.19138 9.52874L11.4239 15.7612C11.7914 16.1287 12.3839 16.1287 12.7514 15.7612C13.1189 15.3937 13.1189 14.8012 12.7514 14.4337L7.32138 8.99624L12.7589 3.55874C13.1189 3.19874 13.1189 2.59874 12.7514 2.23874Z"
        fill="inherit"
        stroke="inherit"
        strokeWidth="0.5"
      />
    </SvgIcon>
  );
};

export default CheckMarkIcon;
