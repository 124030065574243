import React, { useEffect, useState } from "react";
import Type from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function LoadingScreen() {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoading(true);
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Backdrop open={showLoading} sx={{ zIndex: 2000 }}>
      <CircularProgress disableShrink />
    </Backdrop>
  );
}

LoadingScreen.propTypes = {
  classes: Type.object
};

export default LoadingScreen;
