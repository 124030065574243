import React, { useState, useEffect, useRef } from "react";
import {
  DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
  Paging, SearchPanel, Sorting, Column, Toolbar, Item
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import DropDownButton from "devextreme-react/drop-down-button";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";
import { apiService, axiosError, urlHelpers } from "../../../core";
import ProcessPowerGrid from "./ProcessPowerGrid";
import CreatePowerGrid from "./CreatePowerGrid";

export default function PowerGrids(props) {
  const [remoteDataSource, setRemoteDataSource] = useState();
  const [focusedRowId, setFocusedRowId] = useState();
  const [isProcessFormVisible, setIsProcessFormVisible] = useState(false);
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
  const dataGrid = useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const getPowerGrids = async () => {
      setRemoteDataSource(
        createStore({
          key: "rowGuid",
          loadUrl: urlHelpers.getAbsoluteURL("api/Internal/PowerGrids"),
          onBeforeSend: (e, s) => {
            s.headers = {
              Authorization: `Bearer ${localStorage.getItem("BrandsWebJWT")}`
            };
          }
        })
      );
    };

    getPowerGrids();
  }, []);

  const onFocusedRowChanging = e => {
    setFocusedRowId(e.row && e.row.data?.id);
  };

  const onItemClicked = async e => {
    if (e.itemData.id === 1) {
      setIsProcessFormVisible(true);
    } 
  }

  const openCreateFormChange = async e => {
    setIsCreateFormVisible(e);
  }

  const openProcessFormChange = async e => {
    setIsProcessFormVisible(e);
  }

  const processPowerGrid = async (data) => {
    try {
      dataGrid.current.instance.beginCustomLoading("processing power grid");
      await apiService.post(`api/Internal/PowerGrids/${focusedRowId}`, { payrollNumber: data.prNum });
      refreshPowerGrids();
    } catch (err) {
      if (err.response?.status == 400) {
        enqueueSnackbar(err?.response?.data?.errors[0]?.errorMessage, getErrorSnackbarOptions(closeSnackbar));
      } else {
        enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
      }
    } finally {
      dataGrid.current.instance.endCustomLoading();
    }
  }

  const refreshPowerGrids = () => {
    dataGrid.current.instance.refresh();
  }

  const createPowerGrid = async (data) => {
    try {
      dataGrid.current.instance.beginCustomLoading("adding power grid");
      await apiService.post(`api/Internal/PowerGrids`, data);
      refreshPowerGrids();
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    } finally {
      dataGrid.current.instance.endCustomLoading();
    }
  }

  const actions = [
    { id: 1, text: "Process", icon: "selectall" }
  ];

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <CreatePowerGrid open={isCreateFormVisible} openChange={openCreateFormChange} createPowerGrid={createPowerGrid}></CreatePowerGrid>
      <ProcessPowerGrid open={isProcessFormVisible} openChange={openProcessFormChange} processPowerGrid={processPowerGrid}></ProcessPowerGrid>
      <DataGrid
        ref={dataGrid}
        dataSource={remoteDataSource}
        height="100%"
        width="100%"
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        showBorders={true}
        columnResizingMode={"widget"}
        showColumnLines={false}
        rowAlternationEnabled
        focusedRowEnabled
        onFocusedRowChanged={onFocusedRowChanging}
        autoNavigateToFocusedRow>
        <Toolbar>
          <Item location="before">
            <Button icon="refresh" onClick={refreshPowerGrids} />
          </Item>
          <Item location="before">
            <DropDownButton
              text="Power Grid Actions"
              disabled={!focusedRowId}
              items={actions}
              onItemClick={onItemClicked}
              keyExpr="id"
              displayExpr="text"
            />
          </Item>
          <Item location="after">
            <Button icon="add" onClick={() => setIsCreateFormVisible(true)} />
          </Item>
          <Item name="columnChooserButton" locateInMenu="auto" location="after" />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <SearchPanel visible width={250} />
        <HeaderFilter visible allowSearch />
        <ColumnChooser enabled />
        <Sorting mode="multiple" />
        <ColumnFixing />
        <Paging defaultPageSize={40} />
        <Pager showPageSizeSelector />
        <FilterPanel visible />
        <FilterBuilderPopup />

        <Column dataField="name" />
        <Column dataField="conum" />
        <Column dataField="prnum" />
        <Column dataField="createdBy" />
        <Column dataField="createdDate" dataType="datetime" defaultSortIndex={0} defaultSortOrder="desc" />
        <Column dataField="runDate" dataType="datetime" />
        <Column dataField="status" />
      </DataGrid>
    </Box>
  );
}
