// ACTION TYPES
export const TYPES = {
  OPEN_CHANGE_PASSWORD_MODAL: "OPEN_CHANGE_PASSWORD_MODAL",
  CLOSE_CHANGE_PASSWORD_MODAL: "CLOSE_CHANGE_PASSWORD_MODAL"
};

// ACTION CREATORS
export const openChangePasswordModal = () => ({ type: TYPES.OPEN_CHANGE_PASSWORD_MODAL });
export const closeChangePasswordModal = () => ({ type: TYPES.CLOSE_CHANGE_PASSWORD_MODAL });

// REDUCER
const initialState = {
  isModalOpened: false
};

export const widgetChangePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        isModalOpened: true
      };
    case TYPES.CLOSE_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        isModalOpened: false
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsModalOpened = state => state.widgetChangePassword.isModalOpened;
