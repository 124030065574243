import React from "react";

import SnackbarNotificationsClose from "../components/SnackbarNotificationsClose";

import { store } from "../redux/configureStore";
import { hideSnackbar } from "../redux/ducks/snackbarDuck";

const createAction = closeSnackbar => key => (
  <SnackbarNotificationsClose
    onClick={() => {
      closeSnackbar(key);
    }}
  />
);

const onClose = () => {
  store.dispatch(hideSnackbar());
};

export const getErrorSnackbarOptions = closeSnackbar => ({
  variant: "error",
  autoHideDuration: 4000,
  onClose: onClose,
  action: createAction(closeSnackbar)
});

export const getWarningSnackbarOptions = closeSnackbar => ({
  variant: "error",
  autoHideDuration: null,
  onClose: onClose,
  action: createAction(closeSnackbar)
});

export const getSuccessSnackbarOptions = closeSnackbar => ({
  variant: "success",
  autoHideDuration: 3000,
  onClose: onClose,
  action: createAction(closeSnackbar)
});
