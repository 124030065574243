import React from "react";
import { Route } from "react-router";
import SuperAdminLayout from "./SuperAdminLayout";
import Payrolls from "./Payrolls";
import PowerGrids from "./PowerGrids";
import PipMsgTypes from "./PipMsgTypes";
import PayrollDetails from "./PayrollDetails";

function SuperAdminRoutes(props) {
  return (
    <SuperAdminLayout>
      <Route path="/internal/super_admin/payroll/details" component={() => <PayrollDetails />} />
      <Route path="/internal/super_admin/payroll" exact component={() => <Payrolls />} />
      <Route path="/internal/super_admin/powerGrids" component={() => <PowerGrids />} />
      <Route path="/internal/super_admin/pipMsgTypes" component={() => <PipMsgTypes />} />

    </SuperAdminLayout>
  );
}

export default SuperAdminRoutes;
