import { combineReducers } from "redux";
import { appReducer } from "./ducks/appDuck";
import { dashboardReducer } from "./ducks/dashboardDuck";
import { employeesReducer } from "./ducks/employeesDuck";
import { employeeReducer } from "./ducks/employeeDuck";
import { essReducer } from "./ducks/essDuck";
import { onboardingReducer } from "./ducks/onboardingDuck";
import { payhistoryReducer } from "./ducks/payHistoryDuck";
import { snackbarReducer } from "./ducks/snackbarDuck";
import { widgetChangePasswordReducer } from "./ducks/widgetChangePasswordDuck";
import { widgetEssReducer } from "./ducks/widgetEssDuck";
import { widgetFeedbackReducer } from "./ducks/widgetFeedbackDuck";
import { widgetManageProfile } from "./ducks/widgetManageProfileDuck";
import { widgetNewDownloadReducer } from "./ducks/widgetNewDownloadDuck";
import { widgetSelectCompanyReducer } from "./ducks/widgetSelectCompanyDuck";
import { widgetTwoFactorAuth } from "./ducks/widgetTwoFactorAuthDuck";
import { widgetGlobalSearch } from "./ducks/widgetGlobalSearchDuck";
import { payrollsReducer } from "./ducks/payrollsDuck";
import { payrollReducer } from "./ducks/payrollDuck";

export default combineReducers({
  app: appReducer,
  snackbar: snackbarReducer,
  dashboard: dashboardReducer,
  ess: essReducer,
  widgetFeedback: widgetFeedbackReducer,
  widgetNewDownload: widgetNewDownloadReducer,
  widgetSelectCompany: widgetSelectCompanyReducer,
  widgetChangePassword: widgetChangePasswordReducer,
  widgetEss: widgetEssReducer,
  employees: employeesReducer,
  employee: employeeReducer,
  onboarding: onboardingReducer,
  payhistory: payhistoryReducer,
  widgetManageProfile: widgetManageProfile,
  widgetTwoFactorAuth: widgetTwoFactorAuth,
  widgetGlobalSearch,
  payrolls: payrollsReducer,
  payroll: payrollReducer,
});
