// ACTION TYPES
export const TYPES = {
  SET_EMPNUM: "SET_EMPNUM",
  SET_EMPLOYEE: "SET_EMPLOYEE",
  SET_STATE_TAX: "SET_STATE_TAX",
  SET_DEDUCTION_AND_PAYS: "SET_DEDUCTION_AND_PAYS",
  SET_TIME_AND_LABOR: "SET_TIME_AND_LABOR",
  SET_EMPLOYEE_DECK: "SET_EMPLOYEE_DECK",
  SET_DROPDOWN_EMPLOYEE_PERSONAL_INFO: "SET_DROPDOWN_EMPLOYEE_PERSONAL_INFO",
  SET_DROPDOWN_EMPLOYEE_PAY: "SET_DROPDOWN_EMPLOYEE_PAY",
  SET_DROPDOWN_EMPLOYEE_JOB: "SET_DROPDOWN_EMPLOYEE_JOB",
  SET_DROPDOWN_DIVISIONS: "SET_DROPDOWN_DIVISIONS",
  SET_DROPDOWN_DEPARTMENTS: "SET_DROPDOWN_DEPARTMENTS",
  SET_DROPDOWN_DIRECT_DEPOSIT: "SET_DROPDOWN_DIRECT_DEPOSIT",
  SET_DROPDOWN_DEDUCTIONS: "SET_DROPDOWN_DEDUCTIONS",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_STATE_TAX: "UPDATE_STATE_TAX",
  UPDATE_TIME_AND_LABOR: "UPDATE_TIME_AND_LABOR",
  UPDATE_SCROLL_DIRECTION: "UPDATE_SCROLL_DIRECTION",
  UPDATE_VISIBLE_SECTIONS: "UPDATE_VISIBLE_SECTIONS"
};

export const setEmpNum = empnum => ({
  type: TYPES.SET_EMPNUM,
  payload: {
    empnum
  }
});

export const setEmployee = payload => ({
  type: TYPES.SET_EMPLOYEE,
  payload
});

export const setStateTax = payload => ({
  type: TYPES.SET_STATE_TAX,
  payload
});

export const setDeductionAndPays = payload => ({
  type: TYPES.SET_DEDUCTION_AND_PAYS,
  payload
});

export const setTimeAndLabor = payload => ({
  type: TYPES.SET_TIME_AND_LABOR,
  payload
});

export const setEmployeeDeck = payload => ({
  type: TYPES.SET_EMPLOYEE_DECK,
  payload
});

export const setDropdowEmployeePersonalInfo = payload => ({
  type: TYPES.SET_DROPDOWN_EMPLOYEE_PERSONAL_INFO,
  payload
});

export const setDropdownEmployeePay = payload => ({
  type: TYPES.SET_DROPDOWN_EMPLOYEE_PAY,
  payload
});

export const setDropdownEmployeeJob = payload => ({
  type: TYPES.SET_DROPDOWN_EMPLOYEE_JOB,
  payload
});

export const setDropdownDivisions = payload => ({
  type: TYPES.SET_DROPDOWN_DIVISIONS,
  payload
});

export const setDropdownDepartments = payload => ({
  type: TYPES.SET_DROPDOWN_DEPARTMENTS,
  payload
});

export const setDropdownDirectDeposit = payload => ({
  type: TYPES.SET_DROPDOWN_DIRECT_DEPOSIT,
  payload
});

export const setDropdownDeductions = payload => ({
  type: TYPES.SET_DROPDOWN_DEDUCTIONS,
  payload
});

export const updateProfile = (field, data) => ({
  type: TYPES.UPDATE_PROFILE,
  payload: {
    field,
    data
  }
});

export const updateStateTax = (field, data) => ({
  type: TYPES.UPDATE_STATE_TAX,
  payload: {
    field,
    data
  }
});

export const updateTimeAndLabor = payload => ({
  type: TYPES.UPDATE_TIME_AND_LABOR,
  payload
});

export const updateScrollDirection = payload => ({
  type: TYPES.UPDATE_SCROLL_DIRECTION,
  payload
});

export const updateVisibleSections = payload => ({
  type: TYPES.UPDATE_VISIBLE_SECTIONS,
  payload
});

// REDUCER
const initialState = {
  empnum: undefined,
  employee: {
    isLoading: true
  },
  stateTax: {
    isLoading: true
  },
  deductionAndPays: {
    isLoading: true
  },
  timeAndLabor: {
    isLoading: true
  },
  employeeDeck: {
    isLoading: true
  },
  dropdownEmployeePersonalInfo: {
    isLoading: true
  },
  dropdownEmployeePay: {
    isLoading: true
  },
  dropdownEmployeeJob: {
    isLoading: true
  },
  dropdownDivisions: {
    isLoading: true
  },
  dropdownDepartments: {
    isLoading: true
  },
  dropDownDirectDeposit: {
    isLoading: true
  },
  dropdownDeductions: {
    isLoading: true
  },
  sections: {
    direction: "down",
    data: [
      {
        id: "job",
        visible: false
      },
      {
        id: "pay",
        visible: false
      },
      {
        id: "pto",
        visible: false
      },
      {
        id: "deductions-earnings",
        visible: false
      },
      {
        id: "direct-deposit",
        visible: false
      },
      {
        id: "federal-taxes",
        visible: false
      },
      {
        id: "state-taxes",
        visible: false
      },
      {
        id: "local-taxes",
        visible: false
      },
      {
        id: "time-labor",
        visible: false
      },
      {
        id: "employeeDeck",
        visible: false
      }
    ]
  }
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_EMPNUM:
      return {
        ...state,
        ...initialState,
        empnum: action.payload.empnum
      };
    case TYPES.SET_EMPLOYEE:
      return {
        ...state,
        employee: action.payload
      };
    case TYPES.SET_STATE_TAX:
      return {
        ...state,
        stateTax: action.payload
      };
    case TYPES.SET_DEDUCTION_AND_PAYS:
      return {
        ...state,
        deductionAndPays: action.payload
      };
    case TYPES.SET_TIME_AND_LABOR:
      return {
        ...state,
        timeAndLabor: action.payload
      };
    case TYPES.SET_EMPLOYEE_DECK:
      return {
        ...state,
        employeeDeck: action.payload
      };
    case TYPES.SET_DROPDOWN_EMPLOYEE_PERSONAL_INFO:
      return {
        ...state,
        dropdownEmployeePersonalInfo: action.payload
      };
    case TYPES.SET_DROPDOWN_EMPLOYEE_PAY:
      return {
        ...state,
        dropdownEmployeePay: action.payload
      };
    case TYPES.SET_DROPDOWN_EMPLOYEE_JOB:
      return {
        ...state,
        dropdownEmployeeJob: action.payload
      };
    case TYPES.SET_DROPDOWN_DIVISIONS:
      return {
        ...state,
        dropdownDivisions: action.payload
      };
    case TYPES.SET_DROPDOWN_DEPARTMENTS:
      return {
        ...state,
        dropdownDepartments: action.payload
      };
    case TYPES.SET_DROPDOWN_DIRECT_DEPOSIT:
      return {
        ...state,
        dropDownDirectDeposit: action.payload
      };
    case TYPES.SET_DROPDOWN_DEDUCTIONS:
      return {
        ...state,
        dropdownDeductions: action.payload
      };
    case TYPES.UPDATE_PROFILE:
      return {
        ...state,
        employee: {
          ...state.employee,
          data: {
            ...state.employee.data,
            [action.payload.field]: {
              ...state.employee.data?.[action.payload.field],
              ...action.payload.data
            }
          }
        }
      };
    case TYPES.UPDATE_STATE_TAX:
      return {
        ...state,
        stateTax: {
          ...state.stateTax,
          data: {
            ...state.stateTax.data,
            [action.payload.field]: {
              ...state.stateTax.data?.[action.payload.field],
              ...action.payload.data
            }
          }
        }
      };
    case TYPES.UPDATE_TIME_AND_LABOR:
      return {
        ...state,
        timeAndLabor: {
          ...state.timeAndLabor,
          data: action.payload
        }
      };
    case TYPES.UPDATE_SCROLL_DIRECTION:
      return {
        ...state,
        sections: {
          ...state.sections,
          direction: action.payload
        }
      };
    case TYPES.UPDATE_VISIBLE_SECTIONS:
      return {
        ...state,
        sections: {
          ...state.sections,
          data: state.sections.data.map(item =>
            item.id === action.payload.id ? { ...item, visible: action.payload.inView } : item
          )
        }
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectEmpnum = state => state.employee.empnum;
export const selectEmployee = state => state.employee.employee;
export const selectStateTax = state => state.employee.stateTax;
export const selectDeductionAndPays = state => state.employee.deductionAndPays;
export const selectTimeAndLabor = state => state.employee.timeAndLabor;
export const selectEmployeeDeck = state => state.employee.employeeDeck;
export const selectDropdownEmployeePersonalInfo = state =>
  state.employee.dropdownEmployeePersonalInfo;
export const selectDropdownEmployeePay = state => state.employee.dropdownEmployeePay;
export const selectDropdownEmployeeJob = state => state.employee.dropdownEmployeeJob;
export const selectDropdownDivisions = state => state.employee.dropdownDivisions;
export const selectDropdownDepartments = state => state.employee.dropdownDepartments;
export const selectDropDownDirectDeposit = state => state.employee.dropDownDirectDeposit;
export const selectDropdownDeductions = state => state.employee.dropdownDeductions;
export const selectSections = state => state.employee.sections;
