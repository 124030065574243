// ACTION TYPES
export const TYPES = {
  SET_ONBOARDING_STAGES: "SET_ONBOARDING_STAGES",
  SET_SCHEDULED_TIME: "SET_SCHEDULED_TIME"
};

export const setOnboardingStageStatusAC = stages => ({
  type: TYPES.SET_ONBOARDING_STAGES,
  payload: {
    stages
  }
});

export const setScheduledTimeAC = scheduledTime => ({
  type: TYPES.SET_SCHEDULED_TIME,
  payload: {
    scheduledTime
  }
});

// REDUCER
const initialState = {
  stages: [],
  scheduledTime: null
};

export const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_ONBOARDING_STAGES:
      return {
        ...state,
        stages: action.payload.stages
      };
    case TYPES.SET_SCHEDULED_TIME:
      return {
        ...state,
        scheduledTime: action.payload.scheduledTime
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectOnboardingStages = state => state.onboarding.stages;
export const selectScheduledTime = state => state.onboarding.scheduledTime;
