import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { apiService, authService, logErrorsService } from "../core";
import { store } from "../redux/configureStore";
import { setPermissions, setProfile } from "../redux/ducks/appDuck";
import { PAGES } from "../routes/pages";
import { REDIRECT_TO } from "../utils/constants";
import { localStorageSyncApi } from "../core/localStorageSyncApi";
import { sessionStorageSyncApi } from "../core/sessionStorageSyncApi";

export default function useAccountApi() {
  let location = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();

  function isString(myVar) {
    return typeof myVar === "string" || myVar instanceof String;
  }

  const accuntFunctions = useMemo(
    () => ({
      checkRedirectTo({
        redirectTo,
        currentOnboardingStage,
        dashboardOrDefaultPath,
        force = false,
        selectedCpaFirmId,
      } = {}) {
        let prevLocation = location && location.state ? location.state.prevLocation : "";
        if (location?.pathname?.startsWith("/ess/")) prevLocation = location?.pathname ?? "";
        const flow = location && location.state ? location.state.flow : "";
        logErrorsService.info(
          `Entering checkRedirectTo. redirectTo ${redirectTo}, prevLocation ${prevLocation}, flow ${flow}`
        );
        console.log(
          `Entering checkRedirectTo. redirectTo ${redirectTo}, prevLocation ${prevLocation}, flow ${flow}`
        );
        let newPath = { pathname: "", state: {} };
        if (redirectTo === REDIRECT_TO.selectCompany) {
          newPath = { pathname: PAGES.brandsLoginSelectCompany.path, state: { prevLocation } };
        } else if (redirectTo === REDIRECT_TO.ipRestricted) {
          newPath = { pathname: PAGES.restrictedIp.path, state: { prevLocation } };
        } else if (redirectTo === REDIRECT_TO.onboarding) {
          if (location.pathname === PAGES.onboarding.path) {
            newPath = {
              pathname: `${PAGES.onboarding.path}/setup`,
              state: { prevLocation },
            };
          } else if (location.pathname.includes("onboarding") && !force) {
            newPath = { pathname: location.pathname, state: { prevLocation } };
          } else {
            newPath = {
              pathname: `${PAGES.onboarding.path}/setup`,
              state: { prevLocation },
            };
          }
        } else if (redirectTo === REDIRECT_TO.cpaPortal) {
          newPath = {
            pathname: location.pathname.startsWith("/cpa")
              ? location.pathname
              : PAGES.cpaClients.path,
            state: { prevLocation },
          };
        } else if (redirectTo === REDIRECT_TO.addCpaDetails) {
          newPath = {
            pathname: `${PAGES.cpaSignup.path}/${selectedCpaFirmId}`,
            state: { prevLocation },
          };
        } else if (redirectTo === REDIRECT_TO.addCpaUserName) {
          newPath = { pathname: PAGES.cpaProfile.path, state: { prevLocation } };
        } else if (redirectTo === REDIRECT_TO.twoFactorAuth) {
          newPath = { pathname: PAGES.twoFactorAuthLogin.path, state: { prevLocation } };
        }
        if (redirectTo === REDIRECT_TO.setupPhoneNumber) {
          newPath = { pathname: PAGES.setUpAccountPhone.path, state: { flow: "new" } };
        }
        if (redirectTo === REDIRECT_TO.twoFactorAuthSetup) {
          newPath = { pathname: PAGES.setUpAccountPhone.path, state: { flow: "2fa" } };
        }
        if (redirectTo === REDIRECT_TO.dashboard) {
          if (location.pathname.startsWith("/cpa")) {
            newPath = { pathname: PAGES.dashboard.path };
          } else if (location.pathname.startsWith("/ess")) {
            newPath = { pathname: PAGES.dashboard.path };
          } else if (
            prevLocation &&
            prevLocation !== "" &&
            isString(prevLocation) &&
            !prevLocation?.includes("/auth/")
          ) {
            newPath = { pathname: prevLocation };
          } else {
            newPath = { pathname: dashboardOrDefaultPath };
          }
        }
        if (redirectTo === REDIRECT_TO.essDashboard) {
          if (
            prevLocation &&
            prevLocation !== "" &&
            isString(prevLocation) &&
            !prevLocation?.includes("/ess/setup")
          ) {
            newPath = { pathname: prevLocation };
          } else {
            newPath = { pathname: PAGES.essDashboard.path, state: { prevLocation } };
          }
        }
        if (redirectTo === REDIRECT_TO.essSetup) {
          newPath = { pathname: PAGES.essSetup.path, state: { prevLocation } };
        }

        //redirect away from onboarding
        if (
          redirectTo !== REDIRECT_TO.onboarding &&
          location.pathname?.includes("onboarding") &&
          !location.pathname?.includes("employees-onboarding") &&
          (!newPath || !newPath.pathname) &&
          !location.pathname.startsWith(`${PAGES.onboarding.path}/new`)
        ) {
          newPath = { pathname: PAGES.dashboard.path };
        }
        if (newPath && newPath.pathname && newPath.pathname !== location.pathname) {
          const split = newPath.pathname.split("?");
          const search = split?.length > 1 ? split[1] : "";
          logErrorsService.info(`Redirecting to ${newPath.pathname}.`);
          history.push({
            pathname: split[0],
            search: search,
            state: {
              prevLocation: location?.state?.prevLocation,
              ...newPath.state,
            },
          });
        }
      },
      async login(response) {
        this.basicVerifyPasswordLogic(response);
        if (response && response.data) {
          localStorageSyncApi.checkVersion(response.data?.version);
          this.checkRedirectTo({
            ...response.data,
            dashboardOrDefaultPath: PAGES.dashboard.path,
          });
        }

        // Do not remove onboarding guid unless login is successful
        const successfulLoginRedirectPaths = [
          REDIRECT_TO.dashboard,
          REDIRECT_TO.selectCompany,
          REDIRECT_TO.onboarding,
        ];
        if (successfulLoginRedirectPaths.some((r) => r == response?.data?.redirectTo)) {
          localStorageSyncApi.removeGuid();
        }
      },
      async switchProfile(profileType, profileId) {
        let payload = {
          profileType,
          id: profileId,
        };
        const isNative = sessionStorageSyncApi.getIsNative();
        if (isNative) {
          payload.essOnly = true;
        }
        const response = await apiService.post("api/auth/switchprofile", payload);
        // We first switch the token and profile data before
        // Before we were changing the data after redirecting the user
        // These caused issues of api returning data using the old token
        this.basicVerifyPasswordLogic(response);
        // after we switch the token and updated the profile
        // we check if we need to redirect the user somewhere else
        this.checkRedirectTo(
          {
            ...response.data,
            force: true,
          }
          // response.data.redirectTo,
          // null,
          // response.data.currentOnboardingStage,
          // true
        );
        return response.data;
      },
      async getPpxUrl() {
        const { data } = await apiService.get("api/accounts/PPX_URL");
        return data;
      },
      async getPpxSignoutUrl() {
        const { data } = await apiService.get("api/accounts/PPX_LOGOUT_URL");
        return data;
      },
      async getProfile() {
        try {
          let parameters = {};
          const isNative = sessionStorageSyncApi.getIsNative();
          if (isNative) {
            parameters.essOnly = true;
          }
          const response = await apiService.get("api/auth/profile", parameters);
          this.basicVerifyPasswordLogic(response);
          localStorageSyncApi.checkVersion(response.data?.version);
          this.checkRedirectTo(response.data);
          return response.data;
        } catch (err) {
          authService.removeToken();
          console.log("Error getting profile");
          store.dispatch(setProfile(null));
          history.push({
            pathname: PAGES.brandsLogin.path,
            state: { prevLocation: history.location.pathname, error: "Not logged in" },
          });
        }
        return null;
      },
      basicVerifyPasswordLogic(response) {
        if (response.status === 401) {
          try {
            authService.removeToken();
          } catch (error) {
            console.error(error, "error removing token");
          }
          window.location.replace(PAGES.login.path);
        } else {
          const { token, permission, jwtGuid } = response.data;
          authService.saveToken(token);
          authService.saveJwtGuid(jwtGuid);
          let decoded = jwtDecode(token);
          window.serverTimeOffset = decoded.iat - Math.floor(Date.now() / 1000);
          store.dispatch(setProfile(response.data));
          store.dispatch(setPermissions(permission));
        }
      },
      async getSwipeClockUrl() {
        const { data } = await apiService.get("api/SwipeClock/SSO");
        return data.url;
      },
      async getHrNextDirectSsoUrl() {
        const { data } = await apiService.get("api/HrNextDirect");
        return data.url;
      },
      async getSwipeClockSignoutUrl() {
        return "";
      },
      async getSwipeLogins() {
        const { data } = await apiService.get("api/SwipeClock/logins");
        return data;
      },
    }),
    [history, location]
  );
  return accuntFunctions;
}
