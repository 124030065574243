import { consoleError } from "../utils/consoleError";
import { localStorageSyncApi } from "./localStorageSyncApi";
import { sessionStorageSyncApi } from "./sessionStorageSyncApi";

const AUTH_ERROR_MSG = "Request failed with status code 401";

const errorDescription = error => {
  if (error.response && error.response.data) {
    if (typeof error.response.data === "object" && error.response.data.title) {
      return error.response.data.title;
    } else if (typeof error.response.data && error.response.data.message) {
      return error.response.data.message;
    } else if (error.response && error.response.status === 551) {
      return "Please contact customer support to access these reports.";
    }
    if (typeof error.response.data === "object") {
      return JSON.stringify(error.response.data);
    }
    return error.response.data;
  } else {
    return error.message;
  }
};

const checkIsAuthError = error => {
  return error.message === AUTH_ERROR_MSG;
};

const handleError = (error, callback) => {
  if (axiosError.checkIsAuthError(error)) {
    if (window.location.pathname !== "/restricted") {
      localStorageSyncApi.removeJwtToken();
      sessionStorageSyncApi.removeJwtToken();
      window.location.replace("/");
    }
  } else {
    consoleError(error);
    if (callback) {
      callback(error);
    }
  }
};

const axiosError = {
  errorDescription,
  checkIsAuthError,
  handleError
};

export default axiosError;
