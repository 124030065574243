import React, { useState, useEffect, useRef } from "react";
import {
  DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
  Paging, SearchPanel, Sorting, Toolbar, Item, Column
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { apiService, axiosError } from "../../../core";
import { useSnackbar } from "notistack";
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";

export default function PayrollStatusChanges({ payrollNumber }) {
  const dataGrid = React.useRef();
  const [statusChanges, setStatusChanges] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getPayrollStatusChanges = async () => {
    dataGrid.current.instance.beginCustomLoading();
    try {
      const response = await apiService.get(`api/Internal/Payrolls/${payrollNumber}/StatusChanges`);
      setStatusChanges(response?.data);
    } catch (err) {
      enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
    }
    dataGrid.current.instance.endCustomLoading();
};

  useEffect(() => {
    getPayrollStatusChanges();
  }, [payrollNumber]);

  const refreshStatusChanges = () => {
    getPayrollStatusChanges();
  }
  
  return (
    <React.Fragment>
      <DataGrid
      height='100%'
        ref={dataGrid}
        dataSource={statusChanges}
        showBorders={true}>
        <Toolbar>
          <Item location="before">
            <Button icon="refresh" onClick={refreshStatusChanges} />
          </Item>
          <Item name="columnChooserButton" locateInMenu="auto" location="after" />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <SearchPanel visible width={250} />
        <HeaderFilter visible allowSearch />
        <ColumnChooser enabled />
        <Sorting mode="multiple" />
        <ColumnFixing />
        <Paging defaultPageSize={40} />
        <Pager showPageSizeSelector />
        <FilterPanel visible />
        <FilterBuilderPopup />
        <Column dataField="dateTimeChanged" caption="Changed on" dataType="datetime"/>
        <Column dataField="origStatus" caption="Original status" />
        <Column dataField="newStatus" caption="New status" />
        <Column dataField="manualChange" caption="Manual change" />
        <Column dataField="byUser" caption="Changed by" />
        <Column dataField="hostName" caption="Hostname" />
      </DataGrid>
    </React.Fragment>
  );
}