import * as React from 'react';
import { useSnackbar } from "notistack";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { apiService, axiosError } from '../../../core';
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";

export default function AddPayroll(props) {
  const [inputData, setInputData] = React.useState({
    autoHours: true,
    autoPays: true,
    autoDeds: true,
    autoMemos: true,
    accSick: true,
    accVac: true,
    accPer: true,
    calendarId: 0
  });
  const openProp = props.open;
  const [open, setOpen] = React.useState(openProp);
  const [calendars, setCalendars] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    const getCalendars = async () => {
      try {
        const response = await apiService.get(`api/Internal/Payrolls/Calendars`);
        setCalendars(response?.data);
      } catch(err) {
        enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
      }
    };

    if (openProp) {
      getCalendars();
    }
    setOpen(openProp);
  }, [openProp]);

  const handleClose = () => {
    setOpen(false);
    props.openChange(false);
  };

  const handleCalendarChange = (event) => {
    const { target: { value }} = event;
    setInputData({
      ...inputData,
      calendarId: value
    });
  };

  const handleSwitchChange = (event) => {
    setInputData({ ...inputData, [event.target.id]: event.target.checked });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.addPayroll(inputData);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add payroll</DialogTitle>
          <DialogContent>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6} sm={6}>
                <FormControlLabel control={
                  <Switch id="autoHours" checked={inputData.autoHours} onChange={handleSwitchChange}/>
                } label="Auto hours" />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel control={
                  <Switch id="autoPays" checked={inputData.autoPays} onChange={handleSwitchChange}/>
                } label="Auto pays" />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel control={
                  <Switch id="autoDeds" checked={inputData.autoDeds} onChange={handleSwitchChange}/>
                } label="Auto deds" />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel control={
                  <Switch id="autoMemos" checked={inputData.autoMemos} onChange={handleSwitchChange}/>
                } label="Auto memos" />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel control={
                   <Switch id="accSick" checked={inputData.accSick} onChange={handleSwitchChange}/>
                } label="Acc sick" />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel control={
                  <Switch id="accVac" checked={inputData.accVac} onChange={handleSwitchChange}/>
                } label="Acc vac" />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel control={
                  <Switch id="accPer" checked={inputData.accPer} onChange={handleSwitchChange}/>
                } label="Acc per" />
              </Grid>
              <Grid item xs={12} sm={12}>
              <FormControl>
                <InputLabel>Calendar</InputLabel>
                <Select
                  value={inputData.calendarId}
                  onChange={handleCalendarChange}
                  input={<OutlinedInput label="Calendar" />}
                >
                  {calendars.map((c) => (
                    <MenuItem
                      key={c.id}
                      value={c.id}
                    >
                      { `Pay date: ${new Date(c.checkDate).toLocaleDateString("en-US")} | Freq: ${c.frequency} | Start: ${new Date(c.startDate).toLocaleDateString("en-US")} | End: ${new Date(c.endDate).toLocaleDateString("en-US")}` }
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose} type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}