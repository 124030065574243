import { CALENDAR_PERIODS } from "../constants/employeePayHistory";
import {
  getLastDayOfQuarter,
  getFirstDayOfQuarter,
  getLastDayOfYear,
  getFirstDayOfYear
} from "./dateTime";

/**
 * Prepare employee object for Redux State.
 * Convert Payment History from Array to Object.
 *
 * @param {object} employee - employee object from server
 * @returns {object} - employee to save into Redux State
 */
export function addIdsToEmployeePaymentHistory(employee) {
  const paymentHistoryObj = employee.paymentHistory.reduce((acc, cur) => {
    const id = `id_${cur.prnum.toString()}_${cur.checkCounter.toString()}`;
    acc[id] = {
      ...cur,
      id,
      isChecked: false
    };
    return acc;
  }, {});

  return {
    ...employee,
    paymentHistory: paymentHistoryObj
  };
}

/**
 * Prepare body to POST Pay Details request
 *
 * @param {Array} paymentHistory
 * @returns {Array} of objects { prnum, checkCounter }
 */
export function preparePrnumsArray(paymentHistory) {
  return paymentHistory
    .filter(el => el.isChecked)
    .map(el2 => ({ prnum: el2.prnum, checkCounter: el2.checkCounter }));
}

/**
 *
 * @param {Object} filter - employee filter
 * @param {String} filter.calendarPeriod - for example 'THIS_YEAR'
 * @param {String} filter.fromDate - for example '2019-01-01'
 * @param {String} filter.toDate - for example '2019-12-31'
 * @param {Number} filter.empnum - for example 12456
 * @param {Boolean} filter.isAllChecked
 * @param {String} filter.employeeFullName
 * @param {Date} date - current date, example 2019
 * @returns {*}
 */
export function updateFilterCalendarPeriods(filter, date) {
  const { fromDate, toDate, ...restFilter } = filter;
  if (!filter.calendarPeriod) {
    return restFilter;
  }
  if (
    filter.calendarPeriod === CALENDAR_PERIODS.CUSTOM.id &&
    (!filter.fromDate || !filter.toDate)
  ) {
    // Both fromDate and toDate should be set, otherwise we do not change dates
    return restFilter;
  }
  let fromDateNew, toDateNew;

  switch (filter.calendarPeriod) {
    case CALENDAR_PERIODS.THIS_YEAR.id: {
      fromDateNew = getFirstDayOfYear(date);
      toDateNew = getLastDayOfYear(date);
      break;
    }
    case CALENDAR_PERIODS.LAST_YEAR.id: {
      const newDate = new Date(date.getTime());
      newDate.setFullYear(date.getFullYear() - 1);
      fromDateNew = getFirstDayOfYear(newDate);
      toDateNew = getLastDayOfYear(newDate);
      break;
    }
    case CALENDAR_PERIODS.THIS_QUARTER.id: {
      fromDateNew = getFirstDayOfQuarter(date);
      toDateNew = getLastDayOfQuarter(date);
      break;
    }
    case CALENDAR_PERIODS.LAST_QUARTER.id: {
      const newDate = new Date(date.getTime());
      newDate.setMonth(date.getMonth() - 3);
      fromDateNew = getFirstDayOfQuarter(newDate);
      toDateNew = getLastDayOfQuarter(newDate);
      break;
    }
    default: {
      fromDateNew = fromDate;
      toDateNew = toDate;
    }
  }

  return {
    ...restFilter,
    fromDate: fromDateNew,
    toDate: toDateNew
  };
}
