import React from "react";
import Type from "prop-types";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

function SnackbarNotificationsClose(props) {
  const { onClick } = props;
  return (
    <Box
      sx={{
        marginTop: 2,
        "&:hover": {
          cursor: "pointer"
        },
        "& svg": {
          color: "common.white"
        }
      }}
      onClick={onClick}
    >
      <CloseIcon />
    </Box>
  );
}

SnackbarNotificationsClose.propTypes = {
  onClick: Type.func.isRequired
};

export default SnackbarNotificationsClose;
