import React, { useState, useEffect, useRef } from "react";
import {
    DataGrid, ColumnChooser, ColumnFixing, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager,
    Paging, SearchPanel, Sorting, Toolbar, Item, Column
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { Box } from "@mui/system";
import { apiService, axiosError } from "../../../core";
import { useSnackbar } from "notistack";
import { getErrorSnackbarOptions } from "../../../utils/snackbarNotifications";

export default function MpwClientErrors({ payrollNumber }) {
    const dataGrid = useRef();
    const [errors, setErrors] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const getErrors = async () => {
      dataGrid.current.instance.beginCustomLoading();
      try {
        const response = await apiService.get(`api/Internal/Payrolls/${payrollNumber}/mpwClientErrors`);
        setErrors(response?.data);
      } catch (err) {
        enqueueSnackbar(axiosError.errorDescription(err), getErrorSnackbarOptions(closeSnackbar));
      }
      dataGrid.current.instance.endCustomLoading();
    };

    useEffect(() => {
      getErrors();
    }, [payrollNumber]);

    const refreshErrors = () => {
      getErrors();
    }

    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <DataGrid
          dataSource={errors}
          ref={dataGrid}
          height="100%"
          width="100%"
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
          columnResizingMode={"widget"}
          showColumnLines={false}
          rowAlternationEnabled
          focusedRowEnabled
          autoNavigateToFocusedRow
          keyExpr={['prnum', 'error_id']}
        >
          <Toolbar>
            <Item location="before">
              <Button icon="refresh" onClick={refreshErrors} />
            </Item>
            <Item name="columnChooserButton" locateInMenu="auto" location="after" />
            <Item name="searchPanel" locateInMenu="auto" location="after" />
          </Toolbar>
          <SearchPanel visible width={250} />
          <HeaderFilter visible allowSearch />
          <ColumnChooser enabled />
          <Sorting mode="multiple" />
          <ColumnFixing />
          <Paging defaultPageSize={40} />
          <Pager showPageSizeSelector />
          <FilterPanel visible />
          <FilterBuilderPopup />

          <Column dataField="conum" visible={false}/>
          <Column dataField="prnum" visible={false}/>
          <Column dataField="active_error" />
          <Column dataField="error_level" />
          <Column dataField="error_msg" />
          <Column dataField="error_id" />
          <Column dataField="timestamp" visible={false}/>
          <Column dataField="rowguid" />
          <Column dataField="empnum" />
          <Column dataField="chk_counter" />
          <Column dataField="cleared" />
          <Column dataField="user_cleared" />
          <Column dataField="date_cleared" dataType="datetime" />
        </DataGrid>
      </Box>
    );
}
