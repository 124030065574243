// ACTION TYPES
export const TYPES = {
  OPEN_GLOBAL_SEARCH: "OPEN_GLOBAL_SEARCH",
  CLOSE_GLOBAL_SEARCH: "CLOSE_GLOBAL_SEARCH"
};

// ACTION CREATORS
export const openGlobalSearchAC = () => ({ type: TYPES.OPEN_GLOBAL_SEARCH });
export const closeGlobalSearchAC = () => ({ type: TYPES.CLOSE_GLOBAL_SEARCH });

// REDUCER
const initialState = {
  open: false
};

export const widgetGlobalSearch = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_GLOBAL_SEARCH:
      return {
        ...state,
        open: true
      };
    case TYPES.CLOSE_GLOBAL_SEARCH:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsGlobalSearchOpen = state => state.widgetGlobalSearch.open;
