import dayjs from "dayjs";

// ACTION TYPES
export const TYPES = {
  SET_PAYROLL_ID: "SET_PAYROLL_ID",
  SET_CALENDAR_FREQUENCIES: "SET_CALENDAR_FREQUENCIES",
  SET_SELECTED_FREQUENCIES: "SET_SELECTED_FREQUENCIES",
  SET_PERIOD_ID: "SET_PERIOD_ID",
  SET_ROW_SELECTION_MODEL: "SET_ROW_SELECTION_MODEL",
  SET_SELECTED_CALENDARS: "SET_SELECTED_CALENDARS",
  SET_PAY_FREQUENCY: "SET_PAY_FREQUENCY",
  SET_PAY_PERIODS: "SET_PAY_PERIODS",
  SET_CHECK_DATE: "SET_CHECK_DATE",
  SET_PAY_FREQUENCIES: "SET_PAY_FREQUENCIES",
  SET_CALENDAR_IDS: "SET_CALENDAR_IDS",
  SET_PAYEES: "SET_PAYEES",
  SET_EMPLOYEES_IDS: "SET_EMPLOYEES_IDS",
  SET_OPTIONS: "SET_OPTIONS",
  SET_TIME_AND_LABOR_PERIOD: "SET_TIME_AND_LABOR_PERIOD",
  SET_EMPLOYEE_FILTERS: "SET_EMPLOYEE_FILTERS",
};

// ACTION CREATORS
export const setPayrollId = (payrollId) => ({
  type: TYPES.SET_PAYROLL_ID,
  payload: { payrollId },
});

export const setCalendarFrequencies = (calendarFrequencies) => ({
  type: TYPES.SET_CALENDAR_FREQUENCIES,
  payload: { calendarFrequencies },
});

export const setSelectedFrequencies = (selectedFrequencies) => ({
  type: TYPES.SET_SELECTED_FREQUENCIES,
  payload: { selectedFrequencies },
});

export const setPeriodId = (periodId) => ({
  type: TYPES.SET_PERIOD_ID,
  payload: { periodId },
});

export const setRowSelectionModel = (rowSelectionModel) => ({
  type: TYPES.SET_ROW_SELECTION_MODEL,
  payload: { rowSelectionModel },
});

export const setSelectedCalendars = (selectedCalendars) => ({
  type: TYPES.SET_SELECTED_CALENDARS,
  payload: { selectedCalendars },
});

export const setPayFrequency = (payFrequency) => ({
  type: TYPES.SET_PAY_FREQUENCY,
  payload: { payFrequency },
});

export const setPayPeriods = (payPeriods) => ({
  type: TYPES.SET_PAY_PERIODS,
  payload: { payPeriods },
});

export const setCheckDate = (checkDate) => ({
  type: TYPES.SET_CHECK_DATE,
  payload: { checkDate },
});

export const setPayFrequencies = (payFrequencies) => ({
  type: TYPES.SET_PAY_FREQUENCIES,
  payload: { payFrequencies },
});

export const setCalendarIds = (calendarIds) => ({
  type: TYPES.SET_CALENDAR_IDS,
  payload: { calendarIds },
});

export const setPayees = (payees) => ({
  type: TYPES.SET_PAYEES,
  payload: { payees },
});

export const setEmployeesIds = (employeesIds) => ({
  type: TYPES.SET_EMPLOYEES_IDS,
  payload: { employeesIds },
});

export const setOptions = (field, value) => ({
  type: TYPES.SET_OPTIONS,
  payload: { field, value },
});

export const setTimeAndLaborPeriod = (period) => ({
  type: TYPES.SET_TIME_AND_LABOR_PERIOD,
  payload: { period },
});

export const setEmployeeFilters = (employeeFilters) => ({
  type: TYPES.SET_EMPLOYEE_FILTERS,
  payload: { employeeFilters },
});

// REDUCER
const initialState = {
  payrollId: null,
  calendarFrequencies: [],
  selectedFrequencies: [],
  periodId: null,
  rowSelectionModel: [],
  selectedCalendars: {},
  payFrequency: "",
  payPeriods: {},
  checkDate: null,
  payFrequencies: [],
  calendarIds: {},
  payees: "",
  employeesIds: [],
  options: {
    autoHours: true,
    autoPays: true,
    autoDeds: true,
    autoMemos: true,
    accSick: true,
    accPer: true,
    accVac: true,
  },
  timeAndLaborPeriod: {
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
  },
  employeeFilters: [],
};

export const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PAYROLL_ID:
      return {
        ...initialState,
        payrollId: action.payload.payrollId,
      };
    case TYPES.SET_CALENDAR_FREQUENCIES:
      return {
        ...state,
        calendarFrequencies: action.payload.calendarFrequencies,
      };
    case TYPES.SET_SELECTED_FREQUENCIES:
      return {
        ...state,
        selectedFrequencies: action.payload.selectedFrequencies,
      };
    case TYPES.SET_PERIOD_ID:
      return {
        ...state,
        periodId: action.payload.periodId,
      };
    case TYPES.SET_ROW_SELECTION_MODEL:
      return {
        ...state,
        rowSelectionModel: action.payload.rowSelectionModel,
      };
    case TYPES.SET_SELECTED_CALENDARS:
      return {
        ...state,
        selectedCalendars: action.payload.selectedCalendars,
      };
    case TYPES.SET_PAY_FREQUENCY:
      return {
        ...state,
        payFrequency: action.payload.payFrequency,
      };
    case TYPES.SET_PAY_PERIODS:
      return {
        ...state,
        payPeriods: action.payload.payPeriods,
      };
    case TYPES.SET_CHECK_DATE:
      return {
        ...state,
        checkDate: action.payload.checkDate,
      };
    case TYPES.SET_PAY_FREQUENCIES:
      return {
        ...state,
        payFrequencies: action.payload.payFrequencies,
      };
    case TYPES.SET_CALENDAR_IDS:
      return {
        ...state,
        calendarIds: action.payload.calendarIds,
      };
    case TYPES.SET_PAYEES:
      return {
        ...state,
        payees: action.payload.payees,
      };
    case TYPES.SET_EMPLOYEES_IDS:
      return {
        ...state,
        employeesIds: action.payload.employeesIds,
      };
    case TYPES.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.field]: action.payload.value,
        },
      };
    case TYPES.SET_TIME_AND_LABOR_PERIOD:
      return {
        ...state,
        timeAndLaborPeriod: action.payload.period,
      };
    case TYPES.SET_EMPLOYEE_FILTERS:
      return {
        ...state,
        employeeFilters: action.payload.employeeFilters,
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectPayrollId = (state) => state.payroll.payrollId;
export const selectCalendarFrequencies = (state) => state.payroll.calendarFrequencies;
export const selectSelectedFrequencies = (state) => state.payroll.selectedFrequencies;
export const selectPeriodId = (state) => state.payroll.periodId;
export const selectRowSelectionModel = (state) => state.payroll.rowSelectionModel;
export const selectSelectedCalendars = (state) => state.payroll.selectedCalendars;
export const selectPayFrequency = (state) => state.payroll.payFrequency;
export const selectPayPeriods = (state) => state.payroll.payPeriods;
export const selectCheckDate = (state) => state.payroll.checkDate;
export const selectPayFrequencies = (state) => state.payroll.payFrequencies;
export const selectCalendarIds = (state) => state.payroll.calendarIds;
export const selectPayees = (state) => state.payroll.payees;
export const selectEmployeesIds = (state) => state.payroll.employeesIds;
export const selectOptions = (state) => state.payroll.options;
export const selectTimeAndLaborPeriod = (state) => state.payroll.timeAndLaborPeriod;
export const selectPayroll = (skip) => (state) => {
  const calendars = [];

  let parsedCheckDate;
  const importFromTimeAndLabor = state.payroll.payees === "importFromTimeAndLabor";
  const importFromEmployeeFilter = state.payroll.payees?.startsWith("filter-");
  const allActiveEmployees = state.payroll.payees === "all";
  let employeeFilterId = undefined;

  if (state.payroll.checkDate) {
    parsedCheckDate = state.payroll.checkDate.toISOString();
  }

  for (let frequencyId of state.payroll.selectedFrequencies) {
    let startDate = state.payroll.selectedCalendars[frequencyId]?.startDate;
    let endDate = state.payroll.selectedCalendars[frequencyId]?.endDate;
    if (state.payroll.payPeriods?.[frequencyId]?.[0]) {
      startDate = state.payroll.payPeriods?.[frequencyId]?.[0].toISOString();
    }
    if (state.payroll.payPeriods?.[frequencyId]?.[1]) {
      endDate = state.payroll.payPeriods?.[frequencyId]?.[1].toISOString();
    }
    calendars.push({
      ...state.payroll.selectedCalendars[frequencyId],
      startDate,
      endDate,
      payFrequency:
        state.payroll.selectedCalendars[frequencyId]?.frequency ?? state.payroll.payFrequency,
    });
  }

  if (!allActiveEmployees && importFromEmployeeFilter) {
    const employeeFilter = state.payroll.employeeFilters?.find(
      (filter) => filter.id?.toString() === state.payroll.payees?.split("-")?.[1]
    );
    employeeFilterId = employeeFilter?.id;
  }

  return {
    autoHours: state.payroll.options.autoHours,
    autoPays: state.payroll.options.autoPays,
    autoDeds: state.payroll.options.autoDeds,
    autoMemos: state.payroll.options.autoMemos,
    accSick: state.payroll.options.accSick,
    accVac: state.payroll.options.accVac,
    accPer: state.payroll.options.accPer,
    checkDate: parsedCheckDate,
    calendars,
    employeeNumbers: !skip && !allActiveEmployees ? state.payroll.employeesIds : [],
    isEmpty:
      !allActiveEmployees && (!!skip || (!employeeFilterId && !state.payroll.employeesIds?.length)),
    isSameAsLastPayroll: !skip && state.payroll.payees === "same",
    importFromTimeAndLabor: !skip ? importFromTimeAndLabor : false,
    timeAndLaborPeriod:
      !skip && importFromTimeAndLabor
        ? {
            startDate: state.payroll.timeAndLaborPeriod.startDate.toISOString(),
            endDate: state.payroll.timeAndLaborPeriod.endDate.toISOString(),
          }
        : undefined,
    employeeFilterId: !skip ? employeeFilterId : undefined,
  };
};
